 .order-details {

}

	.order-details-field {
		margin-bottom: 1rem;
	}

		.order-details-field-label {
			color: #5C5C5C;
			font-weight: 600;
			font-size: 14px;
		}

		.order-details-field-value {
			color: #5C5C5C;
			font-weight: 400;
			font-size: 14px;
		}

		.order-details-table {
			margin-bottom: 1rem;
		}

			.order-details-table-row {
			}

				.order-details-table-no-lines {
					color: #848484;
					font-size: 14px;
					font-style: italic;
				}

				.order-details-table-line-item {
					display: flex;
					border-bottom: 1px solid #D9D9D9;
					line-height: 2.5;
					color: #272727;
					font-size: 14px;
				}

					.order-details-table-line-item-quanity {
						width: 1.75rem;
						font-weight: 600;
					}

						.order-details-line-x {
							padding: 0 .1rem;
						}

					.order-details-table-line-item-name {
						flex-grow: 1;
					}

					.order-details-table-line-item-total {

					}


					.order-details-table-option {
						display: flex;
						border-bottom: 1px solid #D9D9D9;
						line-height: 2.5;
						color: #848484;
						font-size: 14px;
						padding-left: 1.75rem;
					}

						.order-details-table-option-quanity {
							width: 1.75rem;
						}

						.order-details-table-option-name {
							flex-grow: 1;
						}

						.order-details-table-option-total {

						}

					.order-details-table-subtotal-row {
						display: flex;
						line-height: 2.5;
						font-size: 14px;
						border-bottom: 1px solid #D9D9D9;
						margin-top: 2px;
						border-top: 1px solid #D9D9D9;
						color: #848484;

					}

						.order-details-table-subtotal-row-label {
							font-size: 14px;
							flex-grow: 1;
							font-weight: 400;
						}

						.order-details-table-subtotal-value {

						}

					.order-details-table-discount-row {
						display: flex;
						line-height: 2.5;
						font-size: 14px;
						color: #848484;
						border-bottom: 1px solid #D9D9D9;
					}

						.order-details-table-discount-row-label {
							font-size: 14px;
							flex-grow: 1;
							font-weight: 400;
						}

						.order-details-table-discount-value {

						}

					.order-details-table-total-row {
						display: flex;
						margin-top: 1rem;
					}

						.order-details-table-total-row-label {
							color: #272727;
							font-size: 14px;
							flex-grow: 1;
							font-weight: 600;
						}

						.order-details-table-total-value {

						}