





/**
 * Target indicators
 */

 .widget--targets {
 	padding: 1.5rem 0 2rem 0;
 }

.target-indicator-header {
	text-align: center;
	letter-spacing: 1px;
}

.target-indicator-header h2 {
	font-size: 1.25rem;
	text-transform: uppercase;		
}

	.target-indicator-block {
		padding: 1.75rem;
	}

.barometer {

}

	.barometer-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: .75rem;
	}
		
		.barometer-header-title {
			font-size: .875rem;

		}

		.barometer-header-target {
			font-size: .875rem;			
		}


	.barometer-body {
		position: relative;
		background-color: #CCDBFF;
		height: 4px;
		border-radius: 400px;
	}

		.barometer-target-indicator {
			content: '';
			position: absolute;
			display: block;
			height: 1rem; 
			border-right: 1px solid gray;
			top: 50%;
			transform: translateY(-50%);
		}

		.barometer-fill {
			position: relative;
			width: 10%;
			height: 100%;
			background-color: #4D80FF;
			border-radius: 400px;
			transition: all .5s ease;
		}

			.barometer-value-indicator {
				content: '';
				display: block;
				position: absolute;
				right: -7px;
				height: 13px;
				width: 13px;
				border-radius: 50%;
				background-color: #fff;
				border: 4px solid #4D80FF;
				top: 50%;
				transform: translateY(-50%);

				.tag {
					position: absolute;
					top: 100%;
					left: 0;
					white-space: nowrap;
					font-size: 10px;
				}
			}

			.barometer-emoji {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				width: 1rem;
				background-color: #fff;
			}


			.barometer--with-change-label {
				padding-bottom: 1.5rem;
			}

			
			.barometer-fill--fail { background-color: #DE4C4C; }
			.barometer-fill--fail .barometer-value-indicator { border-color: #DE4C4C; }

			.barometer-fill--close { background-color: #E3C33A; }
			.barometer-fill--close .barometer-value-indicator { border-color: #E3C33A; }

			.barometer-fill--success { background-color: #29DB6D; }
			.barometer-fill--success .barometer-value-indicator { border-color: #29DB6D; }


			.barometer-fill--regular { background-color: $primary-color; }
			.barometer-fill--regular .barometer-value-indicator { border-color: $primary-color; }

			.barometer-value-indicator-number {
				position: absolute;
				display: block;
				top: 100%;
				margin-top: .5rem;
				font-size: 12px;
				left: 50%;
				transform: translateX(-50%);
				white-space: nowrap;
			}		



.barometer--reversed .barometer-fill {
	position: absolute;
	right: 0;
}

	.barometer--reversed .barometer-value-indicator {
		right: auto;
		left: -7px;
	}




.target-date-block {
	border-top: 1px solid #d9d9d9;
	margin-top: 1.5rem;
	padding: .5rem 0rem .5rem 0rem;
	text-align: center;

	.tag {
		font-size: 10px;
	}
}

	.target-date-pretitle {
		font-size: 10px;
		margin-top: 1rem;
	}

	.target-date-title {
		font-size: 18px;
		margin-bottom: .25rem;
	}
