
.thick-bar {
	padding: 1rem;
}

	.thick-bar-track {
		background-color: #C9C9C9;
		height: 2rem;
		border-radius: 8px;
		overflow: hidden;
		display: flex;
	}

	.thick-bar-entry {
		background-color: #2771D5;
		flex-grow: 0;
		flex-shrink: 0;
		transition: width .4s ease;
	}