.widget-style-table {

	overflow: hidden;
	width: 100%;
	overflow: scroll;
	 -webkit-overflow-scrolling: touch;
}

	.widget-style-table-inner {
		width: auto;
	}

.no-stretch {
	width: 1%;
	white-space: nowrap;
}

.style-table-table {
	min-width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	min-width: 100%;
	color: #5C5C5C;
	font-size: 14px;


	th {
		border-top: 1px solid #F4F4F4;
		border-bottom: 1px solid #F4F4F4;
		text-align: left;
		padding: .5rem 1rem;
	}

	td {
		border-top: 1px solid #F4F4F4;
		//border-bottom: 1px solid #F4F4F4;
		text-align: left;
		padding: .5rem 1rem;
	}


}

	.style-table-header {
		background-color: #F4F4F4;
		white-space: nowrap;
		font-size: 14px;
	}
	


.style-table-field {
	white-space: nowrap;

}

	.style-table-field-icon-field {
		width: 3rem;
		padding-right: 0 !important;
	}

	.style-table-field-icon {
		position: relative;
		width: 2rem;
		height: 2rem;
		background-color: #E7EFFB;
		border-radius: 5px;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			fill: #2771D5;
			width: 1.25rem;
			height: 1.25rem;
		}
	}


.style-table-field-main-label-container {
	display: flex;
	align-items: center;
}

	.style-table-field-main-label {
		display: inline-block;
		margin-right: .5rem;
		font-size: 14px;
	}

	.style-table-field-main-label-stars {
		display: flex;
		flex-align: center;
		svg {
			width: 1rem;
			height: 1rem;
			margin-right: 1px;
		}
		span {
			margin-left: .25rem;
		}
	}

.style-table-field-secondary-label-container {
	
}

	.style-table-field-secondary-label {
		font-size: 14px;
		font-style: italic;
		line-height: 1.5;
		color: #A3A3A3;
	}


.style-table-field-label {
	position: relative;
	white-space: normal;
	width: 15rem;
}

.headcol {
	position: sticky;
	left: 0;
  	background-color: white;
  	box-shadow: inset -1px 0 0 #F4F4F4;
  	z-index: 300;
}

.Firstheader {
	position: sticky;
	left: 0;
	background-color: #F4F4F4;
}


.style-table-field-label ~ .tag {
	margin-left: .5rem;
}

.style-table-tag {
	display: inline-block;
	background-color: #F2F7FD;
	color: #2771D5;
	font-size: 12px;
	border-radius: 4px;
	padding: 2px 6px;
}

.style-table-tag--positive {
	color: #00BA07;
}

.style-table-tag--warning {
	color: #E3C33A;
}

.style-table-tag--negative {
	color: #B51818;
}


.style-table-field-button-field {
	width: 1rem;
}

.style-table-button {
	background-color: #E7EFFB;
	border-radius: 5px;
	padding: .5rem 1rem;
	font-size: 12px;
	white-space: nowrap;
	text-align: center;
	color: #2771D5;
	font-weight: 600;
	text-decoration: none;
	border: none;
	cursor: pointer;
}




.style-table-totals-row {
	background-color: #F4F4F4;
	font-weight: 700;
}


/**
 * Specific to rolf table
 */
.style-table-field-scores {
	display: flex;
}

	.style-table-field-score {
		position: relative;
		background-color: #F2F7FD;
		padding: .25rem 0;
		width: 2rem;
		text-align: center;
		margin: 0 .5rem;
		font-size: 14px;
		border-radius: 4px;
		font-weight: 600;
		color: #fff;

		text-decoration: none;
	}


	.style-table-field-score--positive {
		background-color: #00BA07;
	}

	.style-table-field-score--warning {
		background-color: #E3C33A;
	}

	.style-table-field-score--negative {
		background-color: #B51818;
	}

	.style-table-field-score-tooltip {
		display: none;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 130%;
		background-color: #F2F7FD;
		color: #5C5C5C;
		margin-top: .25rem;
		padding: .5rem;
		font-size: 12px;
		border-radius: 5px;
		box-shadow: 0px 0px 10px rgba(0,0,0,.2);
	}

	.style-table-field-score:hover .style-table-field-score-tooltip {
		display: block;
	}



