.brand-bar {
	background-color: $primary-color;
	height: 3.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (min-width: $layout-change) {
		height: 100%;
		width: 4rem;
		flex-direction: column;
		justify-content: start;
		padding-top: 1.25rem;
	}
}

	.brand-bar-logo {
		width: 2.5rem;

		path {
			//fill: #f2eddf;
			fill: #ffffff;
		}
	}

	.brand-bar-title {
		transform-origin: center center;
		transform: rotate(90deg) translateX(1.5rem);
		
		color: #fff;
		font-weight: 600;
		font-size: 1.5rem;
	}