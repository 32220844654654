.spinner {
  //position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  //width: 100vw;
  width: 100%;
  background-color: #fff;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  img {

  }
}
