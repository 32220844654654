.rene-spinner-centered {
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 3.5rem;
}

.rene-spinner {
	position: relative;
	margin: 0 auto;
	width: 3rem;
	height: 3rem;
}

	.rene-spinner-icon-1,
	.rene-spinner-icon-2 {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			fill: #A3A3A3;
		}
	}

	@keyframes spin { 
	    100% { 
	        -webkit-transform: rotate(360deg); 
	        transform:rotate(360deg); 
	    } 
	}

	.rene-spinner-icon-1 svg {
		animation: spin 2s linear infinite;
	}

	.rene-spinner-icon-2 {
		animation: spin 2s linear reverse infinite;
	}
