// .widget-primary-kpis {
// 	background-color: $primary-color;
// 	color: #fff;
// 	border-radius: 4px;
// 	padding: 1rem;
// 	height: 100%;
// }

.widget-rolf-stats {
	@media only screen and (min-width: 90rem) {
		height: 100%;	
	}
}

.rolf-stats {
	background-color: #fff;	
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
	border-radius: 6px;
	@media only screen and (min-width: 90rem) {
		height: 100%;
		margin-bottom: 0rem;
	}
}


	.rolf-primary-stats {
		padding: 1.75rem;
		padding-bottom: 0;
		border-bottom: 1px solid #d9d9d9;
	}

		.rolf-stat-line-primary {
			margin-bottom: 1rem;
			&:last-of-type {
				margin-bottom: 1.5rem;
			}
		}

				.rolf-stat-line-primary .rolf-stat-line-label {
					font-size: 1rem;
					font-weight: 600;
					text-transform: uppercase;
				}

				.rolf-stat-line-primary .rolf-stat-line-value {
					font-size: 2.75rem;	
					line-height: 1.1;
					font-weight: 100;
					@media only screen and (min-width: 60rem) {
						font-size: 3.75rem;	
					}
				}


		.rolf-audits-list {
			max-height: 10rem;
			overflow: scroll;
			a {
				color: inherit;
				text-decoration: none;
			}
		}

			.rolf-audit-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: .5rem;
				border-bottom: 1px solid #d9d9d9;

			}

				.rolf-audit-item-main {
					display: flex;
					align-items: center;
				}

					.rolf-audit-item-icon {
						margin-right: .5rem;
						svg {
							width: 1.5rem;
							height: 1.5rem;
							fill: #393939;
						}	
					}
					
					.rolf-audit-item-title-block {
						font-size: 14px;
						line-height: 1.3;
					}
				
					.rolf-audit-percentage {
						font-size: 14px;
					}

