.widget-v2-container {
	/*font-family: "open sans";*/
	
}

.widget-v2 {
	background-color: #fff;	
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
	border-radius: 6px;
}

.widget-v2-container--timeline-main .widget-v2 {
	margin-bottom: 0;
}

.widget--loading {
	opacity: .6;
}

	
	.widget-v2-header {
		padding: 1.5rem;
		border-bottom: 1px solid #d9d9d9;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.widget-v2-header-title-block {
			.widget-v2-title {}
		}

		.widget-v2-title {
			margin: 0;
			font-size: 1rem;
			font-weight: 400;
			color: #5C5C5C;
		}

		.widget-v2-subtitle {	
			font-size: .8rem;
			font-weight: 400;
			color: #5C5C5C;
			margin: 0;
		}


		.widget-v2-header-legends {
			display: flex;
			max-width: 50%;
			flex-wrap: wrap;
			justify-content: flex-end;
		}

			.widget-v2-header-legend {
				display: flex;
				align-items: center;
				margin-left: 1rem;
				line-height: 2;
			}

				.widget-v2-header-legend-dot {
					background-color: red;
					border-radius: 50%;
					width: 4px;
					height: 4px;
					margin-right: 5px;
				}

				.widget-v2-header-legend-title {
					font-size: 12px;
				}


	.widget-v2-body {
	}

		.spinner-widget-container {
			height: 20rem;
		}

		.widget-v2-body-legends {
			display: flex;
			max-width: 40rem;
			margin: 0 auto;
			margin-bottom: 1rem;
			flex-wrap: wrap;
			justify-content: center;
		}

			.widget-v2-body-legend {
				display: flex;
				align-items: center;
				margin-left: 1rem;
				line-height: 2;
			}

				.widget-v2-body-legend-dot {
					background-color: red;
					border-radius: 50%;
					width: 4px;
					height: 4px;
					margin-right: 5px;
				}

				.widget-v2-body-legend-title {
					font-size: 12px;
				}



	.widget-v2-footer {
		overflow: hidden;
		padding: 1.5rem;
		border-top: 1px solid #d9d9d9;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}


	.widget-footer-filters {
		margin: 0;
		padding: 0;
		list-style: none;
	}

		.widget-footer-filter {
			display: inline-block;
			margin-right: 1rem;
			font-size: 14px;
			cursor: pointer;
		}

		.widget-footer-filter--active {
			color: $primary-color;
		}


	.widget-timeline-v2-footer-settings {
		float: right;
	}

		.widget-timeline-v2-footer-button {
			border: 1px solid gray;
			background-color: transparent;		
			font-family: 'brown';
			padding: .4rem .75rem;
			border-radius: 3px;
			outline: none;
			cursor: pointer;
			margin-left: .5rem;
			/*font-family: 'open sans';*/
		}

		.widget-timeline-v2-footer-button--active {
			background-color: #4D80FF;
			border: 1px solid #4D80FF;
			color: #fff;
		}

		.widget-timeline-v2-footer-button-green--active {
			background-color: #29D0A9 !important;
			border: 1px solid #29D0A9 !important;
			color: #fff;
		}


