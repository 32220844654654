// .widget-primary-kpis {
// 	background-color: $primary-color;
// 	color: #fff;
// 	border-radius: 4px;
// 	padding: 1rem;
// 	height: 100%;
// }

.widget-waste-stats {
	// @media only screen and (min-width: $layout-change) {
	// 	height: 100%;	
	// }
}

.waste-stats {
	position: relative;
	background-color: #fff;	
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
	border-radius: 6px;
	padding: 1.75rem;
	height: 10rem;
	@media only screen and (min-width: $layout-change) {
		//height: 100%;
	}
}


	.primary-stats {

	}


	// .secondary-stats {
	// 	// max-width: 20rem;
		
	// 	@media only screen and (min-width: 45rem) {
	// 		columns: 2;
	// 	}

	// 	@media only screen and (min-width: 60rem) {
	// 		columns: 1;
	// 	}

	// 	@media only screen and (min-width: 70rem) {
	// 		columns: 2;
	// 	}

	// 	@media only screen and (min-width: 80rem) {
	// 		columns: 1;
	// 	}
	// }

		// .stat-line-secondary {
		// 	display: flex;
		// 	font-size: .9rem;
		// 	margin-bottom: .5rem;
		// 	max-width: 20rem;

		// 	// @media only screen and (min-width: 70rem) {
		// 	// 	display: block;
		// 	// 	margin-bottom: 1.5rem;
		// 	// 	&:last-of-type {margin-bottom: 0}
		// 	// }

		// 	@media only screen and (min-width: 95rem) {
		// 		display: flex;
		// 		margin-bottom: .5rem;
		// 		&:last-of-type {margin-bottom: 0}
		// 	}
		// }

		// 	.stat-line-secondary .stat-line-label {
		// 		font-weight: 600;
		// 		text-transform: uppercase;
		// 		width: 60%;

		// 		// @media only screen and (min-width: 70rem) {
		// 		// 	width: 100%;
		// 		// }

		// 		@media only screen and (min-width: 95rem) {
		// 			width: 60%;
		// 		}
		// 	}

		// 	.stat-line-secondary .stat-line-value {
		// 		width: 40%;

		// 		// @media only screen and (min-width: 70rem) {
		// 		// 	width: 100%;
		// 		// }

		// 		@media only screen and (min-width: 95rem) {
		// 			width: 40%;
		// 		}
		// 	}

