/**
 * Basic styling
 *
 * $primary-color: #4D80FF;
 * $dark-gray: #6E6E6E;
 */


 /**
  * Vendor
  */
// @import 'vendor/normalize';

 /**
  * Basics
  */
 // @import 'basics/mixins';
 @import 'basics/colors';
 @import 'basics/fonts';
 @import 'basics/typography';
 // @import 'basics/forms';
 


 /**
  * Fragments (component parts)
  */
@import 'fragments/button';
@import 'fragments/loading';
@import 'fragments/breadcrumbs';
@import 'fragments/featured-stats';
@import 'fragments/spinner';

/**
 * Modules (components)
 */
@import 'modules/widget';
@import 'modules/widget-v3';
@import 'modules/widget-department-table';
@import 'modules/widget-style-table';
@import 'modules/widget-sales-by-channel';
@import 'modules/widget-main-stats';
@import 'modules/widget-rolf-stats';
@import 'modules/widget-kpis';
@import 'modules/widget-profile-details';
@import 'modules/widget-profile-consent';
@import 'modules/widget-waste-stats';
@import 'modules/widget-targets';
@import 'modules/widget-timeline';
@import 'modules/widget-heatmap-main';
@import 'modules/widget-reviews';
@import 'modules/widget-event-history';
@import 'modules/filters';
@import 'modules/quicklink';
@import 'modules/simple-bars';
@import 'modules/thick-bar';
@import 'modules/drawer';
@import 'modules/order-details';


@import 'modules/widget-social-media-stats';
@import 'modules/widget-social-media-channels';
@import 'modules/widget-social-media-instagram-top-posts';


/**
 * Partials
 */
@import 'partials/layout';
@import 'partials/brand-bar';
@import 'partials/navigation';
@import 'partials/login';




/**
 * Code below is PRE SCSS. Waiting to be refactored into seperate imports
**************************/


.MuiTableFooter-root .MuiTableCell-root {
	border-bottom: 0;
}
	

/**
 * Partial: Front page card
 */

.front-page-card-wrapper {
	position: relative;
	height: 100vh;
}
	.front-page-card {
	 	position: relative;
	 	max-width: 50rem;
	 	margin: 0 auto;
	 	padding: 2rem;
		top: 50%;
		transform: translateY(-55%);
		text-align: center;
		@media only screen and (min-width: 60rem) {

		}
	}

		.front-page-card .apron-icon {
			width: 10rem;
			margin-bottom: 2rem;
		}
		@media only screen and (min-width: 60rem) {
			.front-page-card .apron-icon {
				width: 13rem;
				margin-bottom: 3rem;
			}
		}	

	 	.front-page-card-title {
	 		margin-top: 0;
	 		margin-bottom: 1rem;

	 		font-size: 2.125rem;
	 		font-weight: 400;
	 	} 

	 	@media only screen and (min-width: 60rem) {
	 		.front-page-card-title {
		 		margin-top: 0;
		 		margin-bottom: 1rem;
		 		font-size: 3.75rem;
		 	}
	 	}

	 	.front-page-card-paragraph {
			margin-bottom: 1.5rem;
	 	}

 	 	@media only screen and (min-width: 60rem) {
 	 		.front-page-card-paragraph {
				margin-bottom: 3rem;
	 		}
 	 	}


/**
 * Module: profile-card
 */

 .profile-card {
 	position: relative;
 	top: 50%;
 	transform: translateY(-50%);
	display: flex;
	align-items: center;

	
 }

.header .profile-card {
	padding-left: 2rem;
	margin-left: .5rem;
}

 .header .profile-card:before {
 	content: '';
 	position: absolute;
 	display: block;
 	top: 50%;
 	transform: translateY(-50%);
 	left: 0;
 	height: 2rem;
 	background-color: lightgray;
 	width: 1px;
 }

 	.profile-card-avatar {
 		background-color: lightgray;
 		width: 2.5rem;
 		height: 2.5rem;
 		border-radius: 50%;
 		margin-right: 1rem;
 		background-size: cover;
 		background-position: center center;
 	}

 	.profile-card-name {
 		/*text-transform: capitalize;*/
 		font-size: .9rem;
 	}

	 	.profile-card-tagline {
			display: inline-block;
			font-size: .75rem;
			margin-top: .25rem;
	 	}


/**
 * Partial: Main Content;
 */

.main-content {
	max-width: 100rem;
	margin: 2rem auto 0 auto;
}


/**
 * Basics: Forms;
 */
form {

}

label {

}

input {

}


/**
 * Fragment: Spinner;
 */

 .spinner-height-wrapper {
 	height: 15rem;
 }

 .spinner-page-wrapper {
 	position: absolute;
 	width: 100%;
 	height: 100%;
 }

.spinner-centered {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #4D80FF;
  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.spinner--white .spinner {
  background-color: #FFFFFF;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}


/**
 * Performance overview
 **/

 .performance-header {
 	margin-bottom: 2rem;
 }


	.performance-header h1 {
		margin-top: 0;
	}

		.spinner-performance-header-container {
			position: relative;
			top: .5rem;
			display: inline-block;
			width: 4rem;
			height: 2rem;
		}


 	.performance-header-titleblock {
 	}

 	.performance-header-filter {

 	}

 	.filter-button-container {
		margin-top: .5rem;
 	}

	.filter-button {
		background-color: #4D80FF;
		border: none;
		color: #fff;
		padding: .5rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: .8rem;
		width: 100%;
	}

	.filter-block-department {
	}

	.filter-block-department select {
		width: 100%;
	}


	.start-date-container {
		margin-top: .5rem; 

	}
		.start-date-container input {
			width: 49%;
			padding: .5rem .5rem;
			margin-right: 2%;
		}
		.start-date-container input:nth-of-type(2) {
			margin-right: 0;
		}

@media only screen and (min-width: 80rem) {
	.performance-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
	}

		.performance-header-filter {
			width: 30%;
		}
}
	
/**
 * Widgets
 */

.widget-container {
	
}

.widget {
	background-color: #fff;	
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
}
	
	.widget-header {
		padding: 2rem 2rem 0 2rem;

	}

		.widget-title {
			margin: 0;
			font-size: .875rem;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: #A2A2A2;
		}

	.widget-content {

	}
		.responsive-line-container {
			height: 23rem;
		}

		.widget-v2-container--timeline-main .responsive-line-container {
			height: 28rem;
		}

		.responsive-line-error {
			background-color: $warning;
			text-align: center;
			color: #fff;
			padding: 1rem;
		}

.widget-container--2-1-split {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}

.widget-container--2-1-split .widget-subcontainer:nth-of-type(1) {
	width: 100%;
	display: flex;
	flex-direction: column;

	order: 2;
}

.x-widget-container--2-1-split .widget-subcontainer:nth-of-type(1) .widget {
	height: calc(50% - 1rem);
}

.widget-container--2-1-split .widget-subcontainer:nth-of-type(2) {
	width: 100%;
	order: 1;
}

.x-widget-container--2-1-split .widget-subcontainer:nth-of-type(2) .widget {
	min-height: 100%;
}

@media only screen and (min-width: 80rem) {

	.widget-container--2-1-split {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 50rem;
		margin-bottom: 2rem;
	}

		.widget-container--2-1-split .widget-subcontainer:nth-of-type(1) {
			width: calc(70% - 2rem);
			display: flex;
			flex-direction: column;

			order: 1;
		}

		.widget-container--2-1-split .widget-subcontainer:nth-of-type(2) {
			width: 30%;
			order: 2;
		}

		/*.widget:last-of-type {
			margin-bottom: 0;
			border-bottom: 0;	
		}*/
}



.widget-block {
	padding: 2.5rem;
	border-bottom: 1px solid #d9d9d9;
}

.x-widget-block:last-of-type {
	border-bottom: none;
}

	.widget-block-title {
		color: #A2A2A2;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: .875rem;
		margin-bottom: .5rem;
	}

	.widget-block-metric {
		position: relative;
		font-size: 3.125rem;
		font-weight: 100;
		margin-bottom: .5rem;
	}

	.widget-block-metric--with-pre {
		padding-left: 2.5rem;
	}

		.widget-block-metric-pre {
			position: absolute;
			top: .3rem;
			left: 0;
			font-size: 1rem;
			font-weight: bold;
		}


	.widget-block-subtitle {
		font-size: .875rem;
		color: #A2A2A2;
		font-style: italic;
		line-height: 1.3;
	}




/**
 * Widget-v2 Products over timer
 */
.widget-timeline-split-container {

}


	.widget-timeline-split-selector {
		display: flex;
		flex-direction: column;
		width: 100%;
		order: 2;
		border-bottom: 1px solid #F4F4F4;

	}


	.widget-timeline-split-timeline {
		width: 100%;
	}


	@media only screen and (min-width: 70rem) {
	
		.widget-timeline-split-container {
			display: flex;
		}	
		
		.widget-timeline-split-selector {
			width: 30%;
			border-left: 1px solid #F4F4F4;
		}

		.widget-timeline-split-timeline {
			width: 70%;
		}

	}

.widget-timeline-input-container {
	border-bottom: 1px solid #F4F4F4;
}

	.widget-timeline-input {
		display: block;
		width: 100%;
		padding: .75rem;
		border: none;
		outline: none;
		font-size: .9rem;
	}



.widget-timeline-product-list-container {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	height: 10rem; /* Only to let flex-grow do it's thing*/
	flex-grow: 1;
}

	.widget-timeline-product-list {
		padding: .75rem;
		margin: 0;
		list-style: none;
		font-size: .85rem;
	}

		.widget-timeline-product-list-item {
			background-color: #F0F0F0;
			border-radius: 3px;
			padding: .4rem .6rem;
			margin-bottom: .4rem;
			cursor: pointer;
			user-select: none;
			transition: background-color .2s ease;
		}

		.widget-timeline-product-list-item:hover {
			background-color: #e3e3e3;
		}


	.widget-timeline-selected-list-container {
		display: flex;
		justify-content: space-between;
	}

	.widget-timeline-selected-list {
		padding: .75rem;
		margin: 0;
		list-style: none;
		font-size: .85rem;
	}

		.widget-timeline-selected-list-item {
			display: inline-block;
			position: relative;
			background-color: #2771D5;
			transition: all .2s ease;
			color: #fff;
			border-radius: 3px;
			padding: .3rem .6rem;
			padding-right: 4rem;
			margin-bottom: .5rem;
			margin-right: .5rem;
		}

			.widget-timeline-selected-list-item-close {
				display: block;
				position: absolute;
				top: 50%;
				right: .6rem;
				transform: translateY(-50%);
				background-color: #fff;
				height: 14px;
				width: 14px;
				border-radius: 50%;
				color: #000;
				text-align: center;
				font-size: .6rem;
				line-height: 1.6;
				cursor: pointer;
				svg {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
				}
			}


		.no-options {
			padding-left: .75rem;
			margin: 0;
			list-style: none;
			font-size: .85rem;
			font-style: italic;
		}

		.product-timeline-clear {
			display: inline-block;
			cursor: pointer;
			padding: 1rem;
			margin: 0;
			list-style: none;
			font-size: .85rem;
			color: #2771D5;
			white-space: nowrap;
		}

		.product-timeline-clear--disabled {
			cursor: default;
			color: gray;
		}


/**
 * Table
 */

.test-table-container {
	padding: 2rem;
}

 .test-table {
 	width: 100%;
 	border-collapse: collapse;
 }

 .test-table th,
 .test-table td {
 	border: 1px solid #A2A2A2;
 	text-align: left;
 	padding: .5rem;
 	font-size: .825rem;
 }



/**
 * Datepicker
 */
 .date-picker-container {
	margin-top: .5rem; 
	position: relative;
	display: flex;
	justify-content: space-between;
	z-index: 20;
 }

	.date-picker-container-arrow {
		background-color: #fff;
		border: 1px solid #d9d9d9;
		width: 2.5rem;
		flex-grow: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
	}

	.date-picker-container-arrow-inner {
	}


	.datepicker-select {
		position: relative;
	}

	.date-picker-container .datepicker-select {
		width: 49%;
		margin-right: .5rem;
		margin-left: .5rem;
	}

		
		.datepicker-select-input {
			display: flex;
			justify-content: space-between;
			background-color: #fff;
			border: 1px solid #d9d9d9;
			color: hsl(0,0%,20%);
			padding: .6rem .75rem;
			font-size: 1rem;
			font-weight: 400;
			cursor: pointer;
			border-radius: 4px;
			
		}


			 	.datepicker-select-body {
			 		flex-grow: 1;
			 	}

			 	.datepicker-select-toggler {
			 		padding: 0 .2rem 0 .75rem;
			 		/*border-left: 1px solid rgba(0,0,0,.3);*/
			 	}


				.arrow {
					border: solid rgba(0,0,0,.2);
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 4px;
					
				}
				
				.up {
				  	transform: rotate(-135deg);
				  	position: relative;
					top: 2px;
				}

				.down {
				  	transform: rotate(45deg);
				  	position: relative;
				  	top: -2px;
				}

				.left {
				  	transform: rotate(-225deg);
				  	position: relative;
					top: 0px;
					right: -2px;
				}

				.right {
				  	transform: rotate(-45deg);
				  	position: relative;
					top: 0px;
					right: 2px;
				}


		.datepicker-select-container {
			position: absolute;
			background-color: #fff;
			box-shadow: 0px 0px 7px rgba(0,0,0,.1);
			z-index: 1;
			top: calc(100% + .25rem);
			left: 0;
			border: 1px solid #d9d9d9;
		}

		.datepicker-select:nth-of-type(3) .datepicker-select-container {
			background-color: #fff;
			box-shadow: 0px 0px 7px rgba(0,0,0,.1);
			position: absolute;
			z-index: 1;
			top: calc(100% + .25rem);
			right: 0;
			left: auto;
			border: 1px solid #d9d9d9;
		}



/**
 * Departments container
 */
.select-department-container {
	position: relative;
	z-index: 22;
	display: flex;
}

.department-selector {
	flex-grow: 1;
	margin-right: .25rem;
}
.select-department-switcher {
	width: 2.5rem;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;
}

.select-department-switcher svg {
	display: block;
	width: 1.5rem;
	fill: #6E6E6E;
}

.select-department-switcher-active {
	border: none;
	background-color: #4D80FF;
}

.select-department-switcher-active svg {
	fill: #fff;
}

/**
 * Pie
 */

 .pie-container {
 	height: 32rem;
 	max-width: 90%;
 	margin-top: 2rem;
 }


/**
 * Shifts
 */

.widget-content--shift-list {
	min-height: 5rem;
}

.shift-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

	.shift-list-item {
		background-color: #EDEDED;
		margin-bottom: .25rem;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
	}

		.shift-list-item-name,
		.shift-list-item-time {

		}

		.shift-is-approved {
			background-color: rgb(41, 208, 169);
			color: #fff;
		}

		.shift-is-sick {
			background-color: rgb(227, 63, 63) !important;
			color: #fff;	
		}
	
	.shift-legends {
		text-align: center;
	}

	.shift-legend {
		display: inline-block;
		text-transform: uppercase;
		font-size: .875rem;
		margin: 1rem;
	}

	.shift-legend-approved:before {
		content: '';
		position: relative;
		top: .125rem;
		display: inline-block;
		background-color: rgb(41, 208, 169);
		width: 1rem;
		height: 1rem;
		margin-right: .5rem;
	}

	.shift-legend-not-approved:before {
		content: '';
		position: relative;
		top: .15rem;
		display: inline-block;
		background-color: #EDEDED;
		width: 1rem;
		height: 1rem;
		margin-right: .5rem;
	}

	.shift-legend-sick:before {
		content: '';
		position: relative;
		top: .15rem;
		display: inline-block;
		background-color: rgb(227, 63, 63);
		width: 1rem;
		height: 1rem;
		margin-right: .5rem;
	}

/**
 * Production table (sits on top of the defualt react-table styling)
 * production-table is our specific table wrapper class
 */
.rene-react-table-container {
	padding: 2rem;
}

.rene-react-table {
	border: 1px solid #d5d5d5 !important;
	border-bottom: none !important;
}
	.rene-react-table-title {
		margin-top: 0;
		text-align: center;
		font-size: 1.25rem;
		margin-bottom: 1.5rem;
	}

	.rene-react-table .rt-thead {
		box-shadow: none !important;
		color: #fff;
	}

	.rene-react-table .rt-thead .rt-th {
	    background-color: #4D80FF !important;
	    text-align: left;
	    border-right: none !important;
	    border-left: none !important;
	    border-bottom: none !important;
	    padding: 1rem !important;

	    font-size: .75rem;
	    font-weight: bold;
	    text-transform: uppercase;
	    
	}

	.rene-react-table * {
	  outline: none !important;
	}

	.XXXX .rene-react-table .rt-thead .rt-th:first-of-type {
	    background-color: #285ee2 !important; /* Darker blue */
	}

	
	.rene-react-table .rt-tbody .rt-tr-group {
		border-bottom: 1px solid #d5d5d5 !important;
	}

	
	.rene-react-table .rt-tbody .rt-td {
		border-right: none !important;	
		border-left: none !important;
		padding: 1rem !important;
	}


	.production-table .rt-tbody .rt-td:first-of-type,
	.production-table .rt-tbody .rt-td:last-of-type {
		background-color: #efefef !important; /* gray */
	}

/**
 * bar chart
 */

.widget-content--bar {
	height: 40rem;
	padding: 2rem;
}

.countdown {
	font-weight: bold;
	font-size: 3rem;
	margin: 0;
}


.timeline-filters {
	float: left;
	list-style: none;
	margin: 0;
	padding: 1rem 2rem 2rem 2rem;
	text-align: center;
}

	.timeline-filter {
		font-family: verdana;
		font-size: 14px;
		margin: 0 .75rem;
		display: inline-block;
		color: #6E6E6E;
		cursor: pointer;
	}

	.timeline-filter--active {
		color: #4D80FF;
	}

.timeline-settings-wrapper {
	float: right;
	padding: 1rem 2rem 2rem 2rem;
}

	.timeline-settings-wrapper .timeline-settings-button {
		border: 1px solid gray;
		background-color: transparent;		
		font-family: 'brown';
		padding: .4rem .75rem;
		border-radius: 3px;
		outline: none;
		cursor: pointer;
		margin-left: .5rem;
	}

	.timeline-settings-wrapper .timeline-settings-button--active {
		background-color: #4D80FF;
		border: 1px solid #4D80FF;
		color: #fff;
	}

	.timeline-settings-button-green--active {
		background-color: #29D0A9 !important;
		border: 1px solid #29D0A9 !important;
		color: #fff;
	}


/**
 * ROLF / Audits
 **/
.widget--audits {
	padding: 1.5rem 0 0 0;	
}

	.audits-header {
		text-align: center;
		letter-spacing: 1px;
	}

	.audits-header h2 {
		font-size: 1.25rem;
		text-transform: uppercase;		
	}

		.audits-score {
			padding: .5rem 0 1rem 0;
			font-size: 4rem;
			font-weight: 200;
			text-align: center;
		}

		.audits-footer {
			text-align: center;
			font-style: italic;
			color: #A2A2A2;
			font-size: 14px;
			margin-bottom: 1rem;
		}

		.audits-footer span {
			font-weight: bold;
		}


	.audits-list {
		padding: 1rem;
	}

		.audit-item {
			display: flex;
			justify-content: space-between;
			background-color: #F2F2F2;
			padding: .5rem;
			margin-bottom: 2px;
		}

			.audit-item svg {
				width: 1rem;
				height: 1rem;
			}

/**
 * Take Away barlines
 */

.barlines-container {
	padding: 2rem;
}

	.barlines {

	}

		.barlines-header {
			display: none;
		}
			
			@media only screen and (min-width: 60rem) {

				.barlines-header {
					display: flex;
					margin-bottom: .5rem;
					font-size: .875rem;
					color: #A2A2A2;
					text-transform: uppercase;
				}
			}


			.barlines-header-line:nth-of-type(1) {
				width: 11.5rem;
				margin-right: 1rem;
			}

			.barlines-header-line:nth-of-type(2) {
				flex-grow: 1;
			}

			.barlines-header-line:nth-of-type(3) {
				width: 12rem;
				margin-left: 1rem;
			}
	

			.barline {
				height: 2.5rem;
				margin-bottom: 4rem;
			}

				@media only screen and (min-width: 60rem) {
					.barline {
						display: flex;
						align-items: center;
						height: 2.5rem;
						margin-bottom: 1rem;
					}
				}

				.barline-header {
					width: 10rem;
					margin-right: 1rem;
					margin-bottom: .5rem;
				}

				@media only screen and (min-width: 60rem) {
					.barline-header {
						margin-bottom: 0;
					}
				}

				.barline-header-dot {
					content: '';
					display: block;
					width: 1rem;
					height: 1rem;
					border-radius: 100%;
					margin-right: .5rem;
					float: left;
				}

				@media only screen and (min-width: 60rem) {
					.barline-header-dot {
						float: none;
					}
				}

				.barline-bar-container {
					flex-grow: 1;
				}

					.barline-bar {
						position: relative;
						background-color: #DDDDDD;
						padding: .5rem;
						margin-bottom: .5rem;
					}

					@media only screen and (min-width: 60rem) {
						.barline-bar {
							position: relative;
							background-color: #DDDDDD;
							padding: .5rem;
						}
					}


						.barline-bar-percentage {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 0%;
							height: 100%;
							background-color: #4D80FF;
							transition: width .4s ease;
						}

						.barline-bar-number {
							position: relative;
							color: #fff;
						}	

				.barline-footer {

				}
				@media only screen and (min-width: 60rem) {
					.barline-footer {
						width: 12rem;
						margin-left: 1rem;
					}
				}

/**
 * Scatterplot scatter chart
 */

 .scatter-plot-container {
 	height: 25rem;
 }

 .widget-container--scatter {
 	margin-bottom: 1rem;
 	padding: 2rem;
 	background-color: #fff;
 	border: 1px solid #D0D0D0;
 }


	.scatterchart-category-select-container {
		padding: 1rem 2rem 0 2rem;
		display: flex;
		justify-content: flex-end;
	}
	
		.scatterchart-category-select {
			width: 15rem;
		}


/**
 * action-card
 */

.action-card {
	background-color: #fff;
	margin-bottom: 2rem;
}

	.action-card-header {
		padding: 1rem;
		border-bottom: 1px solid #BFBFBF;
	}

		.action-card-header h2 {
			margin: 0;
			font-size: 1.1rem;
			color: #5C5C5C;
		}

	.action-card-body {
		padding: 1rem;
		color: #4E4E4E;
	}






