.sales-by-channel-item {
	border-bottom: 1px solid #F4F4F4;
	user-select: none;
	transition: background-color .2s ease;
	cursor: pointer;

	&:last-of-type {
		border-bottom: none;
	}

	&:hover {
		background-color: #F8F8F8;
	}
}

	.sales-by-channel-main {
		display: flex;
		padding: 1.625rem;
		align-items: center;
	}

	
		.sales-by-channel-header {
			margin-bottom: -.5rem;
		}

			.sales-by-channel-title {
				font-size: 14px;
				font-weight: 500;
				margin: 0;
				margin-right: 1.5rem;
				width: 5rem;
				
			}

			.sales-by-channel-subtitle {
				font-size: 12px;
			}


		.sales-by-channel-bar-container {
			flex-grow: 1;
			margin-right: 1.5rem;
		}	

			.sales-by-channel-bar {
				background-color: #E6E6E6;
				display: flex;
				height: 12px;
			}

				.sales-by-channel-bar-fill {
					position: relative;
					width: 0;
					background-color: $primary-color;
					color: $primary-color;
					transition: width .5s ease;
				}

				.sales-by-channel-bar-fill2 {
					position: relative;
					width: 0;
					background-color: $warning;
					color: $warning;
					transition: width .5s ease;
				}

					.sales-by-channel-bar-fill-text {
						position: absolute;
						top: 100%;
						font-size: 12px;
						color: inherit;
					}


		.sales-by-channel-bar-percentage {
			font-size: 14px;
			font-weight: 500;
			width: 3rem;

		}

	.sales-by-channel-details {
		overflow: hidden;
		max-height: 0;
		transition: max-height .3s ease;
	}

		.sales-by-channel-details--open {
			max-height: 10.25rem;
		}
	


		.sales-by-channel-details-inner {
			//background-color: lightseagreen;
			
		}

			.sales-by-channel-details-container {
				display: flex;
				padding: .5rem 1.625rem 2.5rem 1.625rem;
			}

				.sales-by-channel-details-pre-padding {
					//background-color: blue;
					width: 5rem;
					margin-right: 1.5rem;
				}

				
				.sales-by-channel-details-content {
					//background-color: orange;
					flex-grow: 1;
					margin-right: 1.5rem;
					// border-top: 1px solid #F4F4F4;
					// padding-top: 1rem;
				}

					.sales-by-channel-details-content-kpis {
						display: flex;
						justify-content: space-between;
						color: #747474;
					}

						.sales-by-channel-details-content-kpi {
						}

							.sales-by-channel-details-content-kpi-label {
								font-size: .85rem;
							}

							.sales-by-channel-details-content-kpi-value {
								font-size: 1.25rem;
							}



					.orders-by-type-container {
						background-color: seagreen;
						margin-top: 3rem;
					}

						.orders-by-type-bar {
							background-color: grey;
							height: .5rem;
							display: flex;
						}

							.orders-by-type-bar-section {
								position: relative;
								background-color: $primary-color;
								width: 20%;
								height: 100%;
								font-size: 12px;
								color: #747474;
								&:last-of-type {
									background-color: #72DBA7;
									
									.orders-by-type-bar-section-label,
									.orders-by-type-bar-section-value {
										right: 0;
									}
								}
							}

								.orders-by-type-bar-section-label {
									position: absolute;
									bottom: 100%;
									margin-bottom: 3px;
									font-size: 14px;
								}

								.orders-by-type-bar-section-value {
									position: absolute;
									top: 100%;
									margin-top: 3px;
									font-size: 12px;
								}



				.sales-by-channel-details-post-padding {
					//background-color: red;
					width: 3rem;
				}

