.button {
	// position: relative;
	// display: inline-block;
	// background-color: #4D80FF;
	// border: 1px solid #4D80FF;
	// padding: .5rem 1.25rem;
	// font-size: 1rem;
	// border-radius: 4px;
	// color: #fff;
	// cursor: pointer;
	// text-decoration: none;
	// white-space: nowrap;
	// font-weight: 100;
	// font-family: inherit;
	// outline: none;


	display: inline-block;
	background-color: $primary-color;
	color: #fff;

	text-decoration: none;
	text-align: center;

	padding: 0.75rem 1rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;

	font-weight: 600;

	&.button--light {
		background-color: #ffffff;
		color: $primary-color;
	}
}

.button--large {
	padding: 1rem 3rem;
	font-size: 1rem;
}

.button--ghost {
	background-color: transparent !important;
	color: #4D80FF;
}

.button--tertiary {
	background-color: #20B97E;
	border-color: #20B97E;
}

.button--warning {
	background-color: #e63c3c;
	border-color: #e63c3c;
}

.button--positive {
	background-color: seagreen;
	border-color: seagreen;
	color: seagreen;
}

.button--has-icon {
	padding-left: 2.5em
}

.button--disabled {
	cursor: default;
	background-color: gray;
	border-color: gray;
	opacity: .4;
}

.button--not-rounded {
	border-radius: 0;
}

	.button-lock-icon {
		position: absolute;
		left: 1rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1em;
		height: 1em;
		margin-right: .25rem;

	}

.button--title-button {
	margin-left: .5rem;
}

.title--with-button {
	display: flex;
	align-items: center;
}

.title--with-button .button--title-button {
	font-size: .9rem;

}	