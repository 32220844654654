.quicklink-container {

}

	.quicklink-anchor {
		display: block;
		border: 1px solid #d9d9d9;
		margin-bottom: 1rem;
		border-radius: 6px;
		padding: 1rem;

		display: flex;

		align-items: center;

		justify-content: center;



		text-decoration: none;
		color: inherit;
		//background: $primary-color;
	}

		.quicklink-icon {
			width: 3rem;
			margin-right: 1rem;
			flex-shrink: 0;
			svg {
				display: block;
				margin-left: -.4rem;
			}

			@media only screen and (min-width: 50rem) {
				width: 4rem;
			}
		}

		.quicklink-label {
			//flex-grow: 2;
			text-transform: uppercase;
			font-size: 1rem;
			margin-right: 1rem;
			@media only screen and (min-width: 50rem) {
				font-size: 1.2rem;
			}

		}

		.quicklink-arrow {
			width: 1rem;

			svg {
				fill: #707070;
			}
		}