.widget-kpis {

}

.widget-kpis--full-width {
	@media only screen and (min-width: 60rem) {
		display: flex;
	}
}

	.widget-kpis-main-stat {
		padding: 1rem;
		padding-bottom: 0;
	}

	.widget-kpis--full-width .widget-kpis-main-stat {
		@media only screen and (min-width: 60rem) {
			width: 13rem;	
			border-right: 1px solid #D9D9D9;
		}
	}

		.widget-kpis-main-stat-label {
			line-height: 1;
			font-size: 14px;
			color: #5C5C5C;
		}

		.widget-kpis-main-stat-value {
			font-size: 3rem;
			font-weight: 600;
			line-height: 1;
			color: #5C5C5C;	
		}



	.widget-kpis-stats {
		padding: 1rem;
	}

	.widget-kpis--full-width .widget-kpis-stats {
		@media only screen and (min-width: 60rem) {
			display: flex;
			flex-grow: 1;
			justify-content: space-between;
		}
	}

		.widget-kpis-stat {
			margin-bottom:.5rem;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.widget-kpis--full-width .widget-kpis-stat {
			@media only screen and (min-width: 60rem) {
				flex-grow: 1;
				margin-right: 1rem;
				margin-bottom: 0;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}


			.kpi-card {
				display: flex;
				align-items: center;
				background-color: #E4EEFA;
				border-radius: 5px;
				padding: .75rem;
			}

				.kpi-card-icon {
					margin-right: 1rem;
					margin-left: .5rem;
					svg {
						display: block;
						width: 2rem;
						height: 2rem;
						fill: #75A4E4;
					}
				}


				.kpi-card-icon.kpi-card-icon--small {
					svg {
						width: 2rem;
						height: 1.5rem;
					}
				}

				.widget-kpis--full-width .kpi-card-icon {
					align-self: flex-start;
				}

				.kpi-card-content {
					flex-grow: 1;
				}

					.kpi-card-label {
						color: #909090;
						font-size: 14px;
						line-height: 1;
					}

					.kpi-card-value {
						color: #5C5C5C;
						font-size: 16px;
						font-weight: 600;
					}

						.kpi-card-value-faded {
							opacity: .7;
							font-size: .8rem;
						}

					.widget-kpis--full-width .kpi-card-value {
						font-size: 1.625rem;
					}

				.kpi-card-info {
					position: relative;	
					align-self: flex-start;
					&:hover .kpi-card-info-tooltip {
						display: block;
					}
				}

					.kpi-card-info-icon {
						position: relative;

						background-color: #F2F7FD;
						width: 1rem;
						height: 1rem;
						border-radius: 50%;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							display: block;
							width: .5rem;
							height: .5rem;
						}
					}


					.kpi-card-info-tooltip {
						display: none;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						background-color: #F2F7FD;
						color: #5C5C5C;
						margin-top: .25rem;
						width: 10rem;
						padding: .5rem;
						font-size: 12px;
						border-radius: 5px;
						box-shadow: 0px 0px 10px rgba(0,0,0,.2);
					}