.widget-social-media-instagram-top-posts {
	.widget-v2-body {}
}

	.top-posts {
		display: flex;
		overflow: scroll;
		padding: .75rem;
	}

		.top-posts-item {
			padding: .75rem;
			&:last-child {
				padding-right: 1.5rem;
			}
		}

			.top-posts-item-image {
				display: block;
				position: relative;
				background-color: gray;
				width: 7rem;
				height: 7rem;
				border-radius: 8px;
				background-size: cover;
				background-position: center;
				color: inherit;
			}

				.top-posts-item-image-overlay {
					position: absolute;
					width: 100%;
					height: 100%;
					background: linear-gradient(219deg, rgba(2,0,36,.7) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 100%);
				}

				.top-posts-item-image-type {
					position: absolute;
					top: .25rem;
					right: .5rem;
					width: 1rem;
					height: 1rem;
					svg {
						fill: #fff;
					}

				}

				.top-posts-item-image-stat {
					position: absolute;
					bottom: .5rem;
					left: 50%;
					transform: translateX(-50%);
					max-width: 100%;
					border-radius: 1rem;
					padding: .125rem .75rem;
					background-color: #fff;
					font-size: 14px;
				}

				.top-posts-item-date {
					text-align: center;
					font-size: 12px;
					margin-top: .4rem;
					color: #8E8F91;
				}