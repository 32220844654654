
.profile-consent-list-wrapper {
	padding: 1rem;
	color: #909090;
}

	.profile-consent-list {
		list-style: none;
		margin: 0;
		padding: 0;	
	}


		.profile-consent-list-item {
			position: relative;
			display: flex;
			margin-bottom: .25rem;
			padding-left: 2rem;
			display: block;
			margin-bottom: 1rem;
		}

			.profile-consent-list-item-icon {
				position: absolute;
				left: 0;
				top: 0px;
				svg {
					width: 1.25rem;
					height: 1.25rem;
				}

				&.profile-consent-list-item-icon--checked svg {
					fill: #2771D5;
				}

				&.profile-consent-list-item-icon--unchecked svg {
					fill: #A0A0A0
				}
			}

			.profile-consent-list-item-label {
				font-size: 16px;
				margin-bottom: .25rem;
			}

			.profile-consent-list-item-value {
				font-size: 14px;
				font-style: italic;

				span {
					display: inline-block;
					padding: 0 .5rem;
					border-radius: 4px;
					background-color: #E5E5E5;
					font-size: 12px;
					font-weight: 600;
					font-style: normal;
				}
			}