.widget-event-history {
	padding: 1rem;
	color: #5C5C5C;
}


	.widget-event-history-list {
		list-style: none;
		margin: 0;
		padding: 0;

		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: 6px;
			top: 2.5rem;
			display: block;
			height: calc(100% - 3rem);
			border-left: 3px solid #B4B4B4;
		}
	}

		.widget-event-history-list-item {
			margin-bottom: .75rem;

			&:first-of-type .widget-event-history-date-header {
				margin-top: 0;
			}
		}

			.widget-event-history-date-header {
				display: flex;
				align-items: center;
				padding-left: 2rem;
				margin-top: 1rem;
			}

				.widget-event-history-date-header-icon {
					width: 1rem;
					margin-right: .5rem;
					svg {
						display: block;
					}
				}

				.widget-event-history-date-header-label {
					font-size: 14px;
					font-weight: 600;
				}


		.widget-event-history-entry {
			display: flex;
			align-items: center;
			position: relative;
			padding-left: 2rem;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				display: block;
				width: 9px;
				height: 9px;
				background-color: #fff;
				border-radius: 50%;
				border: 3px solid #B4B4B4;
			}
		}

			.widget-event-history-entry-time {
				margin-right: .5rem;
			}

			.widget-event-history-entry-tag {
				background-color: #E4EEFA;
				border-radius: 5px;
				padding: .25rem .75rem;
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 700;
				margin: 0 .5rem;
			}

			.widget-event-history-entry-description {
				margin-left: .5rem;
				font-size: 14px;
			}