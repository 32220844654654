.widget-v3-container {
	/*font-family: "open sans";*/	
	font-family: "Source Sans Pro";
}

.widget-v3 {
	background-color: #fff;	
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
	border-radius: 6px;
}

.widget-v3-container--timeline-main .widget-v3 {
	margin-bottom: 0;
}

.widget-v3.widget--loading {
	opacity: .6;
	pointer-events: none;
	user-select: none;
}	

	
	.widget-v3-header {
		padding: 1rem;
		border-bottom: 1px solid #d9d9d9;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}


		.widget-v3-header-icon-container {

		}

			.widget-v3-header-icon {
				position: relative;
				background-color: #efefef;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 5px;
				margin-right: .5rem;
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 1.75rem;
					height: 1.75rem;
					fill: #5C5C5C
				}
			}


		.widget-v3-header-title-group {
			flex-grow: 1;
		}

			.widget-v3-title {
				margin: 0;
				font-size: 1rem;
				line-height: 1.2;
				font-weight: 400;
				color: #5C5C5C;
				font-weight: 600;
				margin-bottom: 0;

			}

			.widget-v3-subtitle {	
				font-size: .8rem;
				font-weight: 400;
				color: #B5B5B5;
				margin: 0;
			}

		.widget-v3-header-actions {
			display: flex;
		}

			.widget-v3-header-action {
				margin-left: .5rem;
				color: #5C5C5C;

				&:nth-of-type(1) {
					margin-left: 0;
				}
			}
			
			// Placeholder
			.widget-v3-action {
				border: 1px solid #d9d9d9;
				border-radius: 5px;
				padding: .5rem 1rem;
				margin-left: 1rem;
				color: #5C5C5C;
			}

			.widget-v3-action-medium {
				width: 7.5rem;
			}

			.widget-v3-action-large {
				width: 10rem;
			}

			.widget-v3-action-extra-large {
				width: 15rem;
			}

			.widget-v3-action-extra-extra-large {
				width:20rem;
			}


			.widget-3-select-single-value-with-icon,
			.widget-3-select-single-value-with-tag {
				display: flex;
				align-items: center;
			}

				.widget-v3-select-icon {
					width: 1.1rem;
					margin-right: .5rem;
					svg {
						display: block;
						fill: #5C5C5C;
					}
				}

				.widget-v3-select-tag {
					background-color: #EDEDED;
					font-size: 10px;
					padding: 4px 8px;
					border-radius: 3px;
					margin-right: .5rem;
					font-weight: 600;
				}

				.widget-v3-select-option-is-selected {

					svg {
						fill: #fff;
					}

					.widget-v3-select-tag {
						color: #5C5C5C;
					}

				}

				.widget-v3-select-label {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

		
	.widget-v3-body {

	}

		.spinner-widget-container {
			height: 20rem;
		}


		.widget-v3-error {
			padding: 1rem 1rem;
			height: 100%;
			width: 100%;
			min-height: 10rem;
			font-weight: 400;
			font-style: italic;
			color: #5C5C5C;
			display: flex;
			text-align: center;
			align-items: center;
			div {
				text-align: center;
				width: 100%;
			}
		}


	.widget-v3-footer {
		overflow: hidden;
		padding: 1rem;
		border-top: 1px solid #d9d9d9;
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			text-decoration: none;
		}
	}


	.widget-v3-footer-filters {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;
		height: 1.8rem;
	}

		.widget-v3-footer-filter {
			display: block;
			margin-right: 1rem;
			font-size: 14px;
			cursor: pointer;
		}

		@keyframes loading {
		  to {
		    background-position:
		      	250% 0,
		        0px 0px, /* title */
		    ;
		  }
		}

		.widget-v3-footer-filter-skeleton {
			display: block;
			background-color: #EFEFEF;
			border-radius: 4px;
			color: #707070;
			height: 1rem;
			width: 4rem;
			text-decoration: none;

			background-repeat: no-repeat;
			background-image:
				/* Animation */					   
				linear-gradient(
			    	90deg, 
			    	rgba(#fff, 0) 0, 
			   		rgba(#fff, .8) 50%, 
			    	rgba(#fff, 0) 100%
			    );
			background-size:
			 	/* Animation */
			 	150px 73px, 
			 	/* Title */
			    100px 16px;

			background-position:
				/* Animation */
				-150% 0,
				/* Title */
			    0px 0px;
			animation: loading 1.5s infinite;

		}


	.widget-v3-footer-settings-three-dots {
		width: 1.75rem;
		height: 1.75rem;
		svg {
			fill: #818181;
		}
	}

	.widget-v3-footer-link {
		display: flex;
		align-items: center;
		height: 1.75rem;
	}

		.widget-v3-footer-link-label {
			color: #818181;
			font-size: 14px;
		}

		.widget-v3-footer-link-icon {
			width: .6rem;
			height: .6rem;
			margin-left:.25rem;
			margin-top: 2px;
			svg {
				display: block;
				fill: #818181;
			}
		}



.widget-v3-responsive-bar-container {
	height: 23rem;
}

.widget-v3-responsive-line-container {
	height: 23rem;
}

.widget-v3-body--profiles-event-timeline {
	height: 23rem;
}

