.filters {
	position: relative;
	margin-bottom: 2rem;
	z-index: 1000;
}

	.filter-preview {
		position: relative;
		border: 1px solid #DDDDDD;
		background-color: #fff;
		padding: 1rem 1.25rem;
		border-radius: 4px;
		user-select: none;
		cursor: pointer;

		@media only screen and (min-width: 50rem) {
			width: 23rem;
		}
	}


	.filter-preview-line {
		color: #6C6C6C;

		svg {
			fill: #6C6C6C;
			width: 1rem;
			margin-right: .5rem;
			transform: translateY(1px);
		}
	}

	.filter-preview-line--compared {
		font-style: italic;
	}


	.filter-preview-line--departments {
		font-weight: 600;
	}

	.three-dots {
		float: right;
		margin-top: .5rem;
		display: flex;
		span {
			display:block;
			width: 5px;
			height: 5px;
			background-color: #717171;
			border-radius: 1rem;
			margin: 0 2px;
		}
	}

.filters-dropdown {
	position: absolute;
	top: calc(100% + .5rem);
	right: 0;
	border: 1px solid #DDDDDD;
	background-color: #fff;
	border-radius: 4px;
	min-width: 18rem;
	box-shadow: 1px 1px 5px rgba(0,0,0,.2);
	display: none;
	width: 100%;
	@media only screen and (min-width: 50rem) {
		width: 30rem;
	}
}

	.filter-open .filters-dropdown {
		display: block;
	}


	.filters-dropdown-body {
		padding: 1.5rem;
		
	}

		.filter-field {
			position: relative;
			margin-bottom: 1.5rem;

			.department-selector,
			.date-selector {
				z-index: 30;
			}
			
		}
		.filter-field--date1 {z-index: 2;}
		.filter-field--date2 {z-index: 1;}

			.filter-field-label {
				display: block;
				font-weight: 600;
				color: #8D8D8D;
				margin-bottom: .6rem;
			}

			.filter-field-subtitle {
				font-style: italic;
				color: #8D8D8D;
				margin: .5rem 0;
			}


	.filters-dropdown-footer {
		border-top: 1px solid #DDDDDD;
		padding: 1rem;
		text-align: center;
		.button {
			margin: 0 .5rem;
		}
	}