// .widget-primary-kpis {
// 	background-color: $primary-color;
// 	color: #fff;
// 	border-radius: 4px;
// 	padding: 1rem;
// 	height: 100%;
// }

.widget-main-stats {
	@media only screen and (min-width: 90rem) {
		height: 100%;	
	}
}

.main-stats {
	margin-bottom: 1rem;
	padding: 1.75rem;
	padding-bottom: .5rem;
	background-color: $primary-color;
	color: #fff;
	border-radius: 6px;
	@media only screen and (min-width: 90rem) {
		height: 100%;
	}
}


	.primary-stats {

	}

		.stat-line-primary {
			margin-bottom: 1rem;
			&:last-of-type {
				margin-bottom: 1.5rem;
			}
		}

				.stat-line-primary .stat-line-label {
					font-size: 1rem;
					font-weight: 600;
					text-transform: uppercase;
				}

				.stat-line-primary .stat-line-value {
					font-size: 2.75rem;	
					line-height: 1.1;
					font-weight: 100;
					@media only screen and (min-width: 60rem) {
						font-size: 3.75rem;	
					}
				}



	.secondary-stats {
		// max-width: 20rem;
		
		@media only screen and (min-width: 45rem) {
			columns: 2;
		}

		// @media only screen and (min-width: 60rem) {
		// 	columns: 1;
		// }

		@media only screen and (min-width: 90rem) {
			columns: 1;
		}

		// @media only screen and (min-width: 80rem) {
		// 	columns: 1;
		// }
	}

		.stat-line-secondary {
			display: flex;
			font-size: .9rem;
			margin-bottom: .5rem;
			max-width: 20rem;

			// @media only screen and (min-width: 70rem) {
			// 	display: block;
			// 	margin-bottom: 1.5rem;
			// 	&:last-of-type {margin-bottom: 0}
			// }

			@media only screen and (min-width: 95rem) {
				display: flex;
				margin-bottom: .5rem;
				&:last-of-type {margin-bottom: 0}
			}

		}

			.stat-line-secondary .stat-line-label {
				font-weight: 600;
				text-transform: uppercase;
				width: 60%;

				// @media only screen and (min-width: 70rem) {
				// 	width: 100%;
				// }

				@media only screen and (min-width: 95rem) {
					width: 60%;
				}
			}

			.stat-line-secondary .stat-line-value {
				width: 40%;

				// @media only screen and (min-width: 70rem) {
				// 	width: 100%;
				// }

				@media only screen and (min-width: 95rem) {
					width: 40%;
				}
			}

