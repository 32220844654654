// .widget-primary-kpis {
// 	background-color: $primary-color;
// 	color: #fff;
// 	border-radius: 4px;
// 	padding: 1rem;
// 	height: 100%;
// }

.widget-social-media-stats {
	flex-grow: 0 !important;
	
}

.social-media-stats {
	background-color: #AA1671;
	color: #fff;
	border: 1px solid #d9d9d9;
	margin-bottom: 1rem;
	border-radius: 6px;
	padding: 1.75rem;
	@media only screen and (min-width: 90rem) {
		height: 100%;
		margin-bottom: 0rem;
	}
}


	.social-media-primary-stats {
		padding-bottom: 0;
	}

		.social-media-stat-line-primary {
			margin-bottom: 1rem;
			&:last-of-type {
				margin-bottom: 1.5rem;
			}
		}

				.social-media-stat-line-primary .social-media-stat-line-label {
					font-size: 1rem;
					font-weight: 600;
					text-transform: uppercase;
				}

				.social-media-stat-line-primary .social-media-stat-line-value {
					font-size: 2.75rem;	
					line-height: 1.1;
					font-weight: 100;
					@media only screen and (min-width: 60rem) {
						font-size: 3.75rem;	
					}
				}

		.social-media-secondary-stats {
			// max-width: 20rem;
			
			@media only screen and (min-width: 45rem) {
				columns: 2;
			}

			// @media only screen and (min-width: 60rem) {
			// 	columns: 1;
			// }

			@media only screen and (min-width: 90rem) {
				columns: 1;
			}

			// @media only screen and (min-width: 80rem) {
			// 	columns: 1;
			// }
		}

			.social-media-secondary-stats-heading {
				font-size: 1.1rem;
				font-weight: bold;
				margin-bottom: .5rem;
			}

			.social-media-stat-line-secondary {
				display: flex;
				font-size: .8rem;
				margin-bottom: .5rem;
				max-width: 20rem;

				// @media only screen and (min-width: 70rem) {
				// 	display: block;
				// 	margin-bottom: 1.5rem;
				// 	&:last-of-type {margin-bottom: 0}
				// }

				@media only screen and (min-width: 95rem) {
					display: flex;
					margin-bottom: .5rem;
					&:last-of-type {margin-bottom: 0}
				}
			}

				.social-media-stat-line-secondary .stat-line-label {
					font-weight: 600;
					text-transform: uppercase;
					width: 60%;

					// @media only screen and (min-width: 70rem) {
					// 	width: 100%;
					// }

					@media only screen and (min-width: 95rem) {
						width: 60%;
					}
				}

				.social-media-stat-line-secondary .stat-line-value {
					width: 40%;

					// @media only screen and (min-width: 70rem) {
					// 	width: 100%;
					// }

					@media only screen and (min-width: 95rem) {
						width: 40%;
					}
				}


		

