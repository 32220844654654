.widget-social-media-channelse {
	.widget-v2-body {}
}

	.social-media-channels {
		padding: 1.5rem;
		overflow: scroll;
		width: 100%;
	}

		.social-media-channels-table {
			min-width: 100%;
			border-collapse: collapse;
			table-layout: fixed;
			th {
				text-align: center;

				font-weight: 100;
				font-size: 12px;
			}
		}

			.social-media-channels-table-row {	
				height: 4rem;
			}

				.social-media-channels-table-field {
					position: relative;
					&:nth-of-type(1) {
						width: 3rem;
					}

					&:nth-of-type(2) {
						width: 8rem;
					}

					&:nth-last-of-type(1) {
						width: 10rem;
						text-align: right;
						padding: 0 1rem;
					}

					&.disabled a {
						opacity: .4;
						cursor: default
					}

				}

					.social-media-channels-table-field-content {
						display: flex;
						align-items: flex-start;
						padding: 0 .5rem;
						justify-content: flex-start;
					}

					.social-media-channels-channel-icon {
						background-color: #EBEBEB;
						width: 2.625rem;
						height: 2.625rem;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 5px;
						svg {
							width: 1.25rem;
							height: 1.25rem;
							fill: #454545;
						}
					}

					.social-media-channels-table-field-button {
						background-color: #EBEBEB;
						border-radius: 5px;
						padding: .5rem 1rem;
						font-size: 12px;
						white-space: nowrap;
					}

					.social-media-channels-channel-icon--instagram,
					.social-media-channels-table-field-button--instagram {
						background-color: #F4E7F4;
						color: #D122D1;
						text-decoration: none;
						svg { fill: #D122D1; }
					}

					.social-media-channels-channel-icon--facebook,
					.social-media-channels-table-field-button--facebook {
						background-color: #D9E1F2;
						color: #3B5998;
						text-decoration: none;
						svg { fill: #3B5998; }
					}

					.social-media-channels-channel-icon--tiktok,
					.social-media-channels-table-field-button--tiktok {
						background-color: #EBEBEB;
						color: #454545;
						text-decoration: none;
						svg { fill: #454545; }
					}

					.social-media-channels-channel-icon--linkedin,
					.social-media-channels-table-field-button--linkedin {
						background-color: #E1EDF8;
						color: #0A67C2;
						text-decoration: none;
						svg { fill: #0A67C2; }
					}

					.social-media-channels-channel-icon--youtube,
					.social-media-channels-table-field-button--youtube {
						background-color: #F5DCDC;
						color: #FF0000;
						text-decoration: none;
						svg { fill: #FF0000; }

					}

					.social-media-channels-table-row--YouTube {
						//opacity: .4;
					}

					.social-media-channels-channel-icon--pinterest,
					.social-media-channels-table-field-button--pinterest {
						// background-color: #EBEBEB;
						color: #454545;
						text-decoration: none;
						// svg { fill: #454545; }

					}

					.social-media-channels-table-row--Pinterest {
						opacity: .4;
					}


			 
					

					.social-media-channels-table-field-title-block {
						padding: 0 1rem;
						white-space: nowrap;

						&.social-media-channels-table-field-title-block--center {
							text-align: center;
							min-width: 8rem;
						}
					}

						.social-media-channels-table-field-title-block-main {
							font-weight: bold;
							font-size: 18px;
							line-height: 1.2;
						}

						.social-media-channels-table-field-title-block-secondary {
							font-size: 12px;
							color: #8E8F91;

							a {
								color: inherit;
								text-decoration: none;
								&:hover {
									text-decoration: underline;
								}
							}
						}

					.social-media-channels-table-field-progress {
						background-color: #EBEBEB;
						margin-left: -.75rem;
						font-weight: 600;
						font-size: 12px;
						padding: .25rem .5rem;
						border-radius: 5px;
						&.social-media-channels-table-field-progress--positive { color: #00BA07; }
						&.social-media-channels-table-field-progress--warning { color: #E3C33A; }
						&.social-media-channels-table-field-progress--negative { color: #B51818; }

					}
