.widget-department-table {
	overflow: hidden;
	width: 100%;
	overflow: scroll;
	 -webkit-overflow-scrolling: touch;
}

	.widget-department-table-inner {
		width: auto;
	}


.department-table-table {
	font-family: 'Open Sans', arial, sans-serif;
	border-collapse: collapse;
	table-layout: fixed;
	min-width: 100%;
	color: #5C5C5C;
	font-size: 14px;


	td, th {
		border-top: 1px solid #F4F4F4;
		border-bottom: 1px solid #F4F4F4;
		text-align: left;
		padding: 1rem;
	}


}

	.department-table-header {
		background-color: #F4F4F4;
		white-space: nowrap;
		font-size: 14px;
	}
	


.department-table-field {
	white-space: nowrap;

}

.department-table-field-main-label-container {
	
}

	.department-table-field-main-label {
		display: inline-block;
		margin-right: .5rem;
		font-size: 14px;
	}

	.department-table-field-main-label-stars {
		display: flex;
		flex-align: center;
		svg {
			width: 1rem;
			height: 1rem;
			margin-right: 1px;
		}
		span {
			margin-left: .25rem;
		}
	}

.department-table-field-secondary-label-container {
	
}

	.department-table-field-secondary-label {
		font-size: 14px;
		font-style: italic;
		line-height: 1.5;
		color: #A3A3A3;
	}

.department-table-field-label ~ .tag {
	margin-left: .5rem;
}

.tag {
	display: inline-block;
	background-color: gray;
	color: #fff;
	font-size: 12px;
	border-radius: 4px;
	padding: 4px 6px;
}

.tag--positive {
	background-color: #00BA07;
}

.tag--warning {
	background-color: #E3C33A;
}

.tag--negative {
	background-color: #B51818;
}


.department-table-totals-row {
	background-color: #F4F4F4;
	font-weight: 700;
}