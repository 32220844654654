.simple-bars-scroll-container {
	max-height: 10rem;
	overflow: scroll;
}

.simple-bars {
	padding: 1rem;
	display: table;
	width: 100%;
}

.simple-bars-list {
	display: table-row-group;
}

.simple-bars-list-item {
	display: flex;	
	align-items: center;
	line-height: 2.2;
	display: table-row;
}


	.simple-bars-list-item-label {
		color: #5C5C5C;
		display: table-cell;
		padding-right: 1rem;
		white-space: nowrap;
	}

		.simple-bars-list-item-label-group {
			display: flex;
			align-items: center;
			line-height: 1;
			max-width: 15rem;
		}

		.simple-bars-activity-wrapper {

		}


		.simple-bars-list-item-label-tag {
			background-color: #EDEDED;
			font-size: 10px;
			padding: 4px 8px;
			border-radius: 3px;
			margin-right: .5rem;
			font-weight: 600;
		}

		.simple-bars-list-item-label-title {
			line-height: 2;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			
		}

	.simple-bars-list-item-bar {
		flex-grow: 1;
		display: table-cell;
		width: 100%;
	}

		.simple-bars-list-item-bar-track {
			position: relative;
			background-color: #C9C9C9;
			height: 12px;
			border-radius: 12px;
			overflow: hidden;
		}

		.simple-bars-list-item-bar-fill {
			position: absolute;
			height: 100%;
			border-radius: 12px;
			transition: width .4s ease;
		}


	.simple-bars-list-item-value {
		margin-left: 1rem;
		width: 2rem;	
		white-space: nowrap;
		font-size: 14px;
	}


	.simple-bars-footer {
		padding: 1rem;
	}

		.simple-bars-legends {
			list-style: none;
			padding: 0;
			margin: 0;
			text-align: center;
		}

			.simple-bars-legend {
				display: inline-flex;
				align-items: center;
				margin: 0 .5rem;
			}

				.simple-bars-legend-dot {
					width: 6px;
					height: 6px;
					margin-right: .5rem;
					border-radius: 50%;
				}

				.simple-bars-legend-label {
					font-size: 14px;					
				}

