.app-container {

	// @media only screen and (min-width: $layout-change) {
	// 	display: flex;
	// }
}

	.app-sidebar {
		@media only screen and (min-width: $layout-change) {
			position: fixed;
			z-index: 10;
			height: 100vh;
			width: 19rem;
			display: flex;
			border-right: 1px solid #D5D5D5;
		}
	}

	.app-content {
		@media only screen and (min-width: $layout-change) {
			padding-left: 19rem;
		}
		
	}

		.app-panel {
			display: none;
			background-color: #fff;
			overflow: hidden;
			flex-direction: column;
			height: calc(100vh - 6.5rem);
						
			@media only screen and (min-width: $layout-change) {
				display: flex;
				position: fixed;
				height: 100%;
				width: 18rem;
				border-right: 1px solid #D5D5D5;
			}
		}


		.navigation-open .page-content {
			display: none;
			@media only screen and (min-width: $layout-change) {
				display: block;
			}
		}


		.page-content {}

		// .mobile-show-app-panel .page-content {
		// 	display: none;
		// 	@media only screen and (min-width: $layout-change) {
		// 		display: block;
		// 	}
		// }

		.app-panel ~ .page-content {
			@media only screen and (min-width: $layout-change) {
				padding-left: 18rem;
			}
		}

			.page-content-inner {
				position: relative;
				padding: 2.25rem 1rem;
				@media only screen and (min-width: $layout-change) {
					padding: 2.25rem 2rem;
				}
			}

			.page-content-inner-centering {
				max-width: 45rem;
				margin: 0 auto;
			}

			.page-content-inner-centering--wide {
				//max-width: 75rem; // This is the max used for the app "T"
				max-width: 90rem;
				margin: 0 auto;
			}

			
				.page-content-header {

					@media only screen and (min-width: 50rem) {
						display: flex;
						justify-content: space-between;
					}
				}


					.page-title {
						margin: 0;
						margin-right: 1rem;
					}

					.page-subtitle {
						color: #A0A0A0;
						text-transform: uppercase;
						font-size: 14px;
						margin: 0;
						margin-top: -.25rem;
						margin-bottom: 1rem;
					}

				.page-description {
					color: #606060;
				}
					
					.page-filters-container {
					}



				.page-charts {

				}

					.page-chart-with-sidebar-element {
						margin-bottom: 1rem;
				
						@media only screen and (min-width: 90rem) {
							display: flex;
							//flex-wrap: wrap;
							flex-wrap: nowrap;
						}
					}

						.page-chart-with-sidebar-element-sidebar {
							width: 100%;
						

							@media only screen and (min-width: 90rem) {
								width: 23rem;
								flex-shrink: 0;
								flex-grow: 1;
								order: 2;
								display: flex;
								flex-direction: column;

								> * {
									flex-shrink: 1;
									flex-grow: 1;
									&:nth-of-type(1) {
										@media only screen and (min-width: 90rem) {
											margin-bottom: 1rem;
										}
									}
								}
							}
						}

						.page-chart-with-sidebar-element-main {
							width: 100%;
							@media only screen and (min-width: 90rem) {
								order: 1;
								margin-right: 1rem;
							}

						}


					.page-with-sidebar {
						@media only screen and (min-width: 90rem) {
							display: flex;
							flex-wrap: nowrap;
						}
					}

						.page-with-sidebar-sidebar {
							@media only screen and (min-width: 90rem) {
								width: 25rem;
							}

						}

						.page-with-sidebar-main {
							width: 100%;
							@media only screen and (min-width: 90rem) {
								margin-left: 1rem;
							}
						}




					.split-chart-container-1-3 {
						margin-bottom: 1rem; 

						@media only screen and (min-width: 75rem) {
							display: flex;
							align-items: stretch;
						}


						.widget-v3-container,
						.widget-v3 {
							height: 100%;
							margin-bottom: 0;
						}
					}

						.split-chart-container-widget:nth-of-type(1) {
							margin-bottom: 1rem;
							@media only screen and (min-width: 75rem) {
								width: 19rem;
								margin-right: 1rem;
								margin-bottom: 0;
							}
						}

						.split-chart-container-widget:nth-of-type(2) {
							flex-grow: 1;
							

							.widget-v3 {
								display: flex;
								flex-direction: column;
							}

							.widget-v3-body {
								flex-grow: 1;
							}

						}





					.split-chart-container-5-boxes {
						display: grid;	
						
						grid-template-columns: 1fr 1rem 1fr 1rem 1fr 1rem 1fr;
						grid-template-rows:  1fr 1rem 1fr;
						margin-bottom: 1rem;
					}


						.split-chart-container-5-boxes-widget {
							background-color: seagreen;
							&:nth-of-type(1) {
								  grid-column-start: 1;
								  grid-column-end: 1;
								  grid-row-start: 1;
								  grid-row-end: 1;

								  height: 8.25rem;
							}

							&:nth-of-type(2) {
								grid-column-start: 3;
								grid-column-end: 3;
								grid-row-start: 1;
								grid-row-end: span 1;	
							}

							&:nth-of-type(3) {
								grid-column-start: 1;
								grid-column-end: 1;
								grid-row-start: 3;
								grid-row-end: 3;	
							}

							&:nth-of-type(4) {
								grid-column-start: 3;
								grid-column-end: 3;
								grid-row-start: 3;
								grid-row-end: 3;	
							}

							&:nth-of-type(5) {
								grid-column-start: 5;
								grid-column-end: 8;
								grid-row-start: 1;
								grid-row-end: 4;	
							}
						}



.redacted {
	color: transparent;
	text-shadow: 0 0 6px rgba(0,0,0,0.7);
}

h1 .redacted {
	color: transparent;
	text-shadow: 0 0 10px rgba(0,0,0,0.7);
}







