.drawer-wrapper {
	overflow: hidden;
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0);
	transition: background-color .2s ease;
}

.drawer--open {
	display: block;
	background-color: rgba(0,0,0,.2);
	.drawer {
		transform: translateX(0);	
	}
}

.drawer--closing {

	background-color: rgba(0,0,0,0);
	.drawer {
		transform: translateX(20rem);
	}
}

.drawer--closed {
	display: none;
}

.drawer--opening {
	display: block;	
	background-color: rgba(0,0,0,0);
	.drawer {
		transform: translateX(20rem);	
	}
}

.drawer {
	position: absolute;
	z-index: 100;
	right: 1rem;
	border-radius: 8px;
	top: 1rem;
	width: 20rem;
	height: calc(100% - 2rem);
	background-color: #fff;
	//border-left: 1px solid #d9d9d9;
	padding: 1.5rem;
	overflow: scroll;
	transform: translateX(20rem);
	transition: transform .2s ease;

}

	.drawer-content {
		
	}