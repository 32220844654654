
.widget-profile-details {
	padding: 1rem;
	color: #5C5C5C;
}

	.widget-profile-details-list {
		list-style: none;
		margin: 0;
		padding: 0;	
	}


		.widget-profile-details-list-item {
			display: flex;
			margin-bottom: .25rem;
			font-size: 14px;
			max-width: 22rem;
			&:first-of-type {
				display: block;
				.widget-profile-details-list-item-value {
					font-size: 2.75rem;
					font-weight: 600;
					line-height: 1.1;
					margin-bottom: .75rem;
					width: 100%;

				}				
			}
		}

			.widget-profile-details-list-item-label {
				width: 50%;
				font-weight: 600;
			}

			.widget-profile-details-list-item-value {
				width: 50%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}


		.widget-profile-details-tags {
			margin-top: 1rem;
		}

			.widget-profile-details-tags-label {
				font-weight: 600;
				margin-bottom: .5rem;
				font-size: 14px;
			}

				.widget-profile-details-tag-list {
					display: block;
					list-style: none;
					padding: 0;
					margin: 0;
				}

					.widget-profile-details-tag-list-item {
						display: inline-block;

						background-color: #E4EEFA;
						border-radius: 5px;
						padding: .25rem .75rem;
						font-size: 12px;
						text-transform: uppercase;
						font-weight: 700;
						margin-right: .5rem;
						margin-bottom: .5rem;
					}