* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
	//background-color: #F7F8FA;
	//background-color: #FFFFFF;
}



h1 {
	font-size: 1.875rem;
	font-weight: 600;
}

h2 {
	font-weight: 400;
}

p {
	font-size: 1rem;
	line-height: 1.4;
}