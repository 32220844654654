@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400&display=swap);
/**
 * Basic styling
 *
 * $primary-color: #4D80FF;
 * $dark-gray: #6E6E6E;
 */
/**
 * Vendor
 */
/**
 * Basics
 */
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
}

h1 {
  font-size: 1.875rem;
  font-weight: 600;
}

h2 {
  font-weight: 400;
}

p {
  font-size: 1rem;
  line-height: 1.4;
}

/**
 * Fragments (component parts)
 */
.button {
  display: inline-block;
  background-color: #2771D5;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}
.button.button--light {
  background-color: #ffffff;
  color: #2771D5;
}

.button--large {
  padding: 1rem 3rem;
  font-size: 1rem;
}

.button--ghost {
  background-color: transparent !important;
  color: #4D80FF;
}

.button--tertiary {
  background-color: #20B97E;
  border-color: #20B97E;
}

.button--warning {
  background-color: #e63c3c;
  border-color: #e63c3c;
}

.button--positive {
  background-color: seagreen;
  border-color: seagreen;
  color: seagreen;
}

.button--has-icon {
  padding-left: 2.5em;
}

.button--disabled {
  cursor: default;
  background-color: gray;
  border-color: gray;
  opacity: 0.4;
}

.button--not-rounded {
  border-radius: 0;
}

.button-lock-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 1em;
  height: 1em;
  margin-right: 0.25rem;
}

.button--title-button {
  margin-left: 0.5rem;
}

.title--with-button {
  display: flex;
  align-items: center;
}

.title--with-button .button--title-button {
  font-size: 0.9rem;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;
}
.breadcrumbs {
  text-transform: uppercase;
  font-weight: 600;
  color: #CECECE;
  font-size: 0.75rem;
  margin-bottom: 2rem;
}

.featured-stats {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.featured-stat-block {
  position: relative;
  margin-right: 0rem;
  flex-shrink: 0;
}
.featured-stat-block.disabled a, .featured-stat-block.disabled button.menu-item-anchor {
  opacity: 0.4;
  cursor: default;
}

.featured-stat-block-content {
  display: flex;
  align-items: flex-start;
  padding: 0 0.5rem;
  justify-content: flex-start;
}

.featured-stat-block-title-block {
  padding: 0 1rem;
  white-space: nowrap;
}
.featured-stat-block-title-block.featured-stat-block-title-block--center {
  text-align: center;
  min-width: 8rem;
}

.featured-stat-block-title-block-main {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
}

.featured-stat-block-title-block-secondary {
  font-size: 12px;
  color: #8E8F91;
}
.featured-stat-block-title-block-secondary a, .featured-stat-block-title-block-secondary button.menu-item-anchor {
  color: inherit;
  text-decoration: none;
}
.featured-stat-block-title-block-secondary a:hover, .featured-stat-block-title-block-secondary button.menu-item-anchor:hover {
  text-decoration: underline;
}

.featured-stat-block-progress {
  background-color: #EBEBEB;
  margin-left: -0.75rem;
  font-weight: 600;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}
.featured-stat-block-progress.social-media-channels-table-field-progress--positive {
  color: #00BA07;
}
.featured-stat-block-progress.social-media-channels-table-field-progress--warning {
  color: #E3C33A;
}
.featured-stat-block-progress.social-media-channels-table-field-progress--negative {
  color: #B51818;
}

.rene-spinner-centered {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 3.5rem;
}

.rene-spinner {
  position: relative;
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
}

.rene-spinner-icon-1,
.rene-spinner-icon-2 {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rene-spinner-icon-1 svg,
.rene-spinner-icon-2 svg {
  fill: #A3A3A3;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rene-spinner-icon-1 svg {
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.rene-spinner-icon-2 {
  animation: spin 2s linear reverse infinite;
}

/**
 * Modules (components)
 */
.widget-v2-container {
  /*font-family: "open sans";*/
}

.widget-v2 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.widget-v2-container--timeline-main .widget-v2 {
  margin-bottom: 0;
}

.widget--loading {
  opacity: 0.6;
}

.widget-v2-header {
  padding: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget-v2-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #5C5C5C;
}

.widget-v2-subtitle {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5C5C5C;
  margin: 0;
}

.widget-v2-header-legends {
  display: flex;
  max-width: 50%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.widget-v2-header-legend {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  line-height: 2;
}

.widget-v2-header-legend-dot {
  background-color: red;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-right: 5px;
}

.widget-v2-header-legend-title {
  font-size: 12px;
}

.spinner-widget-container {
  height: 20rem;
}

.widget-v2-body-legends {
  display: flex;
  max-width: 40rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.widget-v2-body-legend {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  line-height: 2;
}

.widget-v2-body-legend-dot {
  background-color: red;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-right: 5px;
}

.widget-v2-body-legend-title {
  font-size: 12px;
}

.widget-v2-footer {
  overflow: hidden;
  padding: 1.5rem;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget-footer-filters {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget-footer-filter {
  display: inline-block;
  margin-right: 1rem;
  font-size: 14px;
  cursor: pointer;
}

.widget-footer-filter--active {
  color: #2771D5;
}

.widget-timeline-v2-footer-settings {
  float: right;
}

.widget-timeline-v2-footer-button {
  border: 1px solid gray;
  background-color: transparent;
  font-family: "brown";
  padding: 0.4rem 0.75rem;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  margin-left: 0.5rem;
  /*font-family: 'open sans';*/
}

.widget-timeline-v2-footer-button--active {
  background-color: #4D80FF;
  border: 1px solid #4D80FF;
  color: #fff;
}

.widget-timeline-v2-footer-button-green--active {
  background-color: #29D0A9 !important;
  border: 1px solid #29D0A9 !important;
  color: #fff;
}

.widget-v3-container {
  /*font-family: "open sans";*/
  font-family: "Source Sans Pro";
}

.widget-v3 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.widget-v3-container--timeline-main .widget-v3 {
  margin-bottom: 0;
}

.widget-v3.widget--loading {
  opacity: 0.6;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.widget-v3-header {
  padding: 1rem;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.widget-v3-header-icon {
  position: relative;
  background-color: #efefef;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-right: 0.5rem;
}
.widget-v3-header-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 1.75rem;
  height: 1.75rem;
  fill: #5C5C5C;
}

.widget-v3-header-title-group {
  flex-grow: 1;
}

.widget-v3-title {
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
  color: #5C5C5C;
  font-weight: 600;
  margin-bottom: 0;
}

.widget-v3-subtitle {
  font-size: 0.8rem;
  font-weight: 400;
  color: #B5B5B5;
  margin: 0;
}

.widget-v3-header-actions {
  display: flex;
}

.widget-v3-header-action {
  margin-left: 0.5rem;
  color: #5C5C5C;
}
.widget-v3-header-action:nth-of-type(1) {
  margin-left: 0;
}

.widget-v3-action {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  color: #5C5C5C;
}

.widget-v3-action-medium {
  width: 7.5rem;
}

.widget-v3-action-large {
  width: 10rem;
}

.widget-v3-action-extra-large {
  width: 15rem;
}

.widget-v3-action-extra-extra-large {
  width: 20rem;
}

.widget-3-select-single-value-with-icon,
.widget-3-select-single-value-with-tag {
  display: flex;
  align-items: center;
}

.widget-v3-select-icon {
  width: 1.1rem;
  margin-right: 0.5rem;
}
.widget-v3-select-icon svg {
  display: block;
  fill: #5C5C5C;
}

.widget-v3-select-tag {
  background-color: #EDEDED;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 0.5rem;
  font-weight: 600;
}

.widget-v3-select-option-is-selected svg {
  fill: #fff;
}
.widget-v3-select-option-is-selected .widget-v3-select-tag {
  color: #5C5C5C;
}

.widget-v3-select-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spinner-widget-container {
  height: 20rem;
}

.widget-v3-error {
  padding: 1rem 1rem;
  height: 100%;
  width: 100%;
  min-height: 10rem;
  font-weight: 400;
  font-style: italic;
  color: #5C5C5C;
  display: flex;
  text-align: center;
  align-items: center;
}
.widget-v3-error div {
  text-align: center;
  width: 100%;
}

.widget-v3-footer {
  overflow: hidden;
  padding: 1rem;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-v3-footer a, .widget-v3-footer button.menu-item-anchor {
  text-decoration: none;
}

.widget-v3-footer-filters {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 1.8rem;
}

.widget-v3-footer-filter {
  display: block;
  margin-right: 1rem;
  font-size: 14px;
  cursor: pointer;
}

@-webkit-keyframes loading {
  to {
    background-position: 250% 0, 0px 0px;
  }
}

@keyframes loading {
  to {
    background-position: 250% 0, 0px 0px;
  }
}
.widget-v3-footer-filter-skeleton {
  display: block;
  background-color: #EFEFEF;
  border-radius: 4px;
  color: #707070;
  height: 1rem;
  width: 4rem;
  text-decoration: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 150px 73px, 100px 16px;
  background-position: -150% 0, 0px 0px;
  -webkit-animation: loading 1.5s infinite;
          animation: loading 1.5s infinite;
}

.widget-v3-footer-settings-three-dots {
  width: 1.75rem;
  height: 1.75rem;
}
.widget-v3-footer-settings-three-dots svg {
  fill: #818181;
}

.widget-v3-footer-link {
  display: flex;
  align-items: center;
  height: 1.75rem;
}

.widget-v3-footer-link-label {
  color: #818181;
  font-size: 14px;
}

.widget-v3-footer-link-icon {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.25rem;
  margin-top: 2px;
}
.widget-v3-footer-link-icon svg {
  display: block;
  fill: #818181;
}

.widget-v3-responsive-bar-container {
  height: 23rem;
}

.widget-v3-responsive-line-container {
  height: 23rem;
}

.widget-v3-body--profiles-event-timeline {
  height: 23rem;
}

.widget-department-table {
  overflow: hidden;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.widget-department-table-inner {
  width: auto;
}

.department-table-table {
  font-family: "Open Sans", arial, sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  min-width: 100%;
  color: #5C5C5C;
  font-size: 14px;
}
.department-table-table td, .department-table-table th {
  border-top: 1px solid #F4F4F4;
  border-bottom: 1px solid #F4F4F4;
  text-align: left;
  padding: 1rem;
}

.department-table-header {
  background-color: #F4F4F4;
  white-space: nowrap;
  font-size: 14px;
}

.department-table-field {
  white-space: nowrap;
}

.department-table-field-main-label {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 14px;
}

.department-table-field-main-label-stars {
  display: flex;
  flex-align: center;
}
.department-table-field-main-label-stars svg {
  width: 1rem;
  height: 1rem;
  margin-right: 1px;
}
.department-table-field-main-label-stars span {
  margin-left: 0.25rem;
}

.department-table-field-secondary-label {
  font-size: 14px;
  font-style: italic;
  line-height: 1.5;
  color: #A3A3A3;
}

.department-table-field-label ~ .tag {
  margin-left: 0.5rem;
}

.tag {
  display: inline-block;
  background-color: gray;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 6px;
}

.tag--positive {
  background-color: #00BA07;
}

.tag--warning {
  background-color: #E3C33A;
}

.tag--negative {
  background-color: #B51818;
}

.department-table-totals-row {
  background-color: #F4F4F4;
  font-weight: 700;
}

.widget-style-table {
  overflow: hidden;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.widget-style-table-inner {
  width: auto;
}

.no-stretch {
  width: 1%;
  white-space: nowrap;
}

.style-table-table {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  min-width: 100%;
  color: #5C5C5C;
  font-size: 14px;
}
.style-table-table th {
  border-top: 1px solid #F4F4F4;
  border-bottom: 1px solid #F4F4F4;
  text-align: left;
  padding: 0.5rem 1rem;
}
.style-table-table td {
  border-top: 1px solid #F4F4F4;
  text-align: left;
  padding: 0.5rem 1rem;
}

.style-table-header {
  background-color: #F4F4F4;
  white-space: nowrap;
  font-size: 14px;
}

.style-table-field {
  white-space: nowrap;
}

.style-table-field-icon-field {
  width: 3rem;
  padding-right: 0 !important;
}

.style-table-field-icon {
  position: relative;
  width: 2rem;
  height: 2rem;
  background-color: #E7EFFB;
  border-radius: 5px;
}
.style-table-field-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  fill: #2771D5;
  width: 1.25rem;
  height: 1.25rem;
}

.style-table-field-main-label-container {
  display: flex;
  align-items: center;
}

.style-table-field-main-label {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 14px;
}

.style-table-field-main-label-stars {
  display: flex;
  flex-align: center;
}
.style-table-field-main-label-stars svg {
  width: 1rem;
  height: 1rem;
  margin-right: 1px;
}
.style-table-field-main-label-stars span {
  margin-left: 0.25rem;
}

.style-table-field-secondary-label {
  font-size: 14px;
  font-style: italic;
  line-height: 1.5;
  color: #A3A3A3;
}

.style-table-field-label {
  position: relative;
  white-space: normal;
  width: 15rem;
}

.headcol {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  box-shadow: inset -1px 0 0 #F4F4F4;
  z-index: 300;
}

.Firstheader {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #F4F4F4;
}

.style-table-field-label ~ .tag {
  margin-left: 0.5rem;
}

.style-table-tag {
  display: inline-block;
  background-color: #F2F7FD;
  color: #2771D5;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 6px;
}

.style-table-tag--positive {
  color: #00BA07;
}

.style-table-tag--warning {
  color: #E3C33A;
}

.style-table-tag--negative {
  color: #B51818;
}

.style-table-field-button-field {
  width: 1rem;
}

.style-table-button {
  background-color: #E7EFFB;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  color: #2771D5;
  font-weight: 600;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.style-table-totals-row {
  background-color: #F4F4F4;
  font-weight: 700;
}

/**
 * Specific to rolf table
 */
.style-table-field-scores {
  display: flex;
}

.style-table-field-score {
  position: relative;
  background-color: #F2F7FD;
  padding: 0.25rem 0;
  width: 2rem;
  text-align: center;
  margin: 0 0.5rem;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

.style-table-field-score--positive {
  background-color: #00BA07;
}

.style-table-field-score--warning {
  background-color: #E3C33A;
}

.style-table-field-score--negative {
  background-color: #B51818;
}

.style-table-field-score-tooltip {
  display: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 130%;
  background-color: #F2F7FD;
  color: #5C5C5C;
  margin-top: 0.25rem;
  padding: 0.5rem;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.style-table-field-score:hover .style-table-field-score-tooltip {
  display: block;
}

.sales-by-channel-item {
  border-bottom: 1px solid #F4F4F4;
  -webkit-user-select: none;
          user-select: none;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.sales-by-channel-item:last-of-type {
  border-bottom: none;
}
.sales-by-channel-item:hover {
  background-color: #F8F8F8;
}

.sales-by-channel-main {
  display: flex;
  padding: 1.625rem;
  align-items: center;
}

.sales-by-channel-header {
  margin-bottom: -0.5rem;
}

.sales-by-channel-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-right: 1.5rem;
  width: 5rem;
}

.sales-by-channel-subtitle {
  font-size: 12px;
}

.sales-by-channel-bar-container {
  flex-grow: 1;
  margin-right: 1.5rem;
}

.sales-by-channel-bar {
  background-color: #E6E6E6;
  display: flex;
  height: 12px;
}

.sales-by-channel-bar-fill {
  position: relative;
  width: 0;
  background-color: #2771D5;
  color: #2771D5;
  transition: width 0.5s ease;
}

.sales-by-channel-bar-fill2 {
  position: relative;
  width: 0;
  background-color: #DB7272;
  color: #DB7272;
  transition: width 0.5s ease;
}

.sales-by-channel-bar-fill-text {
  position: absolute;
  top: 100%;
  font-size: 12px;
  color: inherit;
}

.sales-by-channel-bar-percentage {
  font-size: 14px;
  font-weight: 500;
  width: 3rem;
}

.sales-by-channel-details {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.sales-by-channel-details--open {
  max-height: 10.25rem;
}

.sales-by-channel-details-container {
  display: flex;
  padding: 0.5rem 1.625rem 2.5rem 1.625rem;
}

.sales-by-channel-details-pre-padding {
  width: 5rem;
  margin-right: 1.5rem;
}

.sales-by-channel-details-content {
  flex-grow: 1;
  margin-right: 1.5rem;
}

.sales-by-channel-details-content-kpis {
  display: flex;
  justify-content: space-between;
  color: #747474;
}

.sales-by-channel-details-content-kpi-label {
  font-size: 0.85rem;
}

.sales-by-channel-details-content-kpi-value {
  font-size: 1.25rem;
}

.orders-by-type-container {
  background-color: seagreen;
  margin-top: 3rem;
}

.orders-by-type-bar {
  background-color: grey;
  height: 0.5rem;
  display: flex;
}

.orders-by-type-bar-section {
  position: relative;
  background-color: #2771D5;
  width: 20%;
  height: 100%;
  font-size: 12px;
  color: #747474;
}
.orders-by-type-bar-section:last-of-type {
  background-color: #72DBA7;
}
.orders-by-type-bar-section:last-of-type .orders-by-type-bar-section-label,
.orders-by-type-bar-section:last-of-type .orders-by-type-bar-section-value {
  right: 0;
}

.orders-by-type-bar-section-label {
  position: absolute;
  bottom: 100%;
  margin-bottom: 3px;
  font-size: 14px;
}

.orders-by-type-bar-section-value {
  position: absolute;
  top: 100%;
  margin-top: 3px;
  font-size: 12px;
}

.sales-by-channel-details-post-padding {
  width: 3rem;
}

@media only screen and (min-width: 90rem) {
  .widget-main-stats {
    height: 100%;
  }
}

.main-stats {
  margin-bottom: 1rem;
  padding: 1.75rem;
  padding-bottom: 0.5rem;
  background-color: #2771D5;
  color: #fff;
  border-radius: 6px;
}
@media only screen and (min-width: 90rem) {
  .main-stats {
    height: 100%;
  }
}

.stat-line-primary {
  margin-bottom: 1rem;
}
.stat-line-primary:last-of-type {
  margin-bottom: 1.5rem;
}

.stat-line-primary .stat-line-label {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.stat-line-primary .stat-line-value {
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 100;
}
@media only screen and (min-width: 60rem) {
  .stat-line-primary .stat-line-value {
    font-size: 3.75rem;
  }
}

@media only screen and (min-width: 45rem) {
  .secondary-stats {
    -webkit-columns: 2;
            columns: 2;
  }
}
@media only screen and (min-width: 90rem) {
  .secondary-stats {
    -webkit-columns: 1;
            columns: 1;
  }
}

.stat-line-secondary {
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  max-width: 20rem;
}
@media only screen and (min-width: 95rem) {
  .stat-line-secondary {
    display: flex;
    margin-bottom: 0.5rem;
  }
  .stat-line-secondary:last-of-type {
    margin-bottom: 0;
  }
}

.stat-line-secondary .stat-line-label {
  font-weight: 600;
  text-transform: uppercase;
  width: 60%;
}
@media only screen and (min-width: 95rem) {
  .stat-line-secondary .stat-line-label {
    width: 60%;
  }
}

.stat-line-secondary .stat-line-value {
  width: 40%;
}
@media only screen and (min-width: 95rem) {
  .stat-line-secondary .stat-line-value {
    width: 40%;
  }
}

@media only screen and (min-width: 90rem) {
  .widget-rolf-stats {
    height: 100%;
  }
}

.rolf-stats {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
}
@media only screen and (min-width: 90rem) {
  .rolf-stats {
    height: 100%;
    margin-bottom: 0rem;
  }
}

.rolf-primary-stats {
  padding: 1.75rem;
  padding-bottom: 0;
  border-bottom: 1px solid #d9d9d9;
}

.rolf-stat-line-primary {
  margin-bottom: 1rem;
}
.rolf-stat-line-primary:last-of-type {
  margin-bottom: 1.5rem;
}

.rolf-stat-line-primary .rolf-stat-line-label {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.rolf-stat-line-primary .rolf-stat-line-value {
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 100;
}
@media only screen and (min-width: 60rem) {
  .rolf-stat-line-primary .rolf-stat-line-value {
    font-size: 3.75rem;
  }
}

.rolf-audits-list {
  max-height: 10rem;
  overflow: scroll;
}
.rolf-audits-list a, .rolf-audits-list button.menu-item-anchor {
  color: inherit;
  text-decoration: none;
}

.rolf-audit-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #d9d9d9;
}

.rolf-audit-item-main {
  display: flex;
  align-items: center;
}

.rolf-audit-item-icon {
  margin-right: 0.5rem;
}
.rolf-audit-item-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #393939;
}

.rolf-audit-item-title-block {
  font-size: 14px;
  line-height: 1.3;
}

.rolf-audit-percentage {
  font-size: 14px;
}

@media only screen and (min-width: 60rem) {
  .widget-kpis--full-width {
    display: flex;
  }
}

.widget-kpis-main-stat {
  padding: 1rem;
  padding-bottom: 0;
}

@media only screen and (min-width: 60rem) {
  .widget-kpis--full-width .widget-kpis-main-stat {
    width: 13rem;
    border-right: 1px solid #D9D9D9;
  }
}

.widget-kpis-main-stat-label {
  line-height: 1;
  font-size: 14px;
  color: #5C5C5C;
}

.widget-kpis-main-stat-value {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  color: #5C5C5C;
}

.widget-kpis-stats {
  padding: 1rem;
}

@media only screen and (min-width: 60rem) {
  .widget-kpis--full-width .widget-kpis-stats {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
}

.widget-kpis-stat {
  margin-bottom: 0.5rem;
}
.widget-kpis-stat:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 60rem) {
  .widget-kpis--full-width .widget-kpis-stat {
    flex-grow: 1;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .widget-kpis--full-width .widget-kpis-stat:last-of-type {
    margin-right: 0;
  }
}

.kpi-card {
  display: flex;
  align-items: center;
  background-color: #E4EEFA;
  border-radius: 5px;
  padding: 0.75rem;
}

.kpi-card-icon {
  margin-right: 1rem;
  margin-left: 0.5rem;
}
.kpi-card-icon svg {
  display: block;
  width: 2rem;
  height: 2rem;
  fill: #75A4E4;
}

.kpi-card-icon.kpi-card-icon--small svg {
  width: 2rem;
  height: 1.5rem;
}

.widget-kpis--full-width .kpi-card-icon {
  align-self: flex-start;
}

.kpi-card-content {
  flex-grow: 1;
}

.kpi-card-label {
  color: #909090;
  font-size: 14px;
  line-height: 1;
}

.kpi-card-value {
  color: #5C5C5C;
  font-size: 16px;
  font-weight: 600;
}

.kpi-card-value-faded {
  opacity: 0.7;
  font-size: 0.8rem;
}

.widget-kpis--full-width .kpi-card-value {
  font-size: 1.625rem;
}

.kpi-card-info {
  position: relative;
  align-self: flex-start;
}
.kpi-card-info:hover .kpi-card-info-tooltip {
  display: block;
}

.kpi-card-info-icon {
  position: relative;
  background-color: #F2F7FD;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
.kpi-card-info-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  width: 0.5rem;
  height: 0.5rem;
}

.kpi-card-info-tooltip {
  display: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #F2F7FD;
  color: #5C5C5C;
  margin-top: 0.25rem;
  width: 10rem;
  padding: 0.5rem;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.widget-profile-details {
  padding: 1rem;
  color: #5C5C5C;
}

.widget-profile-details-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-profile-details-list-item {
  display: flex;
  margin-bottom: 0.25rem;
  font-size: 14px;
  max-width: 22rem;
}
.widget-profile-details-list-item:first-of-type {
  display: block;
}
.widget-profile-details-list-item:first-of-type .widget-profile-details-list-item-value {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0.75rem;
  width: 100%;
}

.widget-profile-details-list-item-label {
  width: 50%;
  font-weight: 600;
}

.widget-profile-details-list-item-value {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widget-profile-details-tags {
  margin-top: 1rem;
}

.widget-profile-details-tags-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.widget-profile-details-tag-list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-profile-details-tag-list-item {
  display: inline-block;
  background-color: #E4EEFA;
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.profile-consent-list-wrapper {
  padding: 1rem;
  color: #909090;
}

.profile-consent-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-consent-list-item {
  position: relative;
  display: flex;
  margin-bottom: 0.25rem;
  padding-left: 2rem;
  display: block;
  margin-bottom: 1rem;
}

.profile-consent-list-item-icon {
  position: absolute;
  left: 0;
  top: 0px;
}
.profile-consent-list-item-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.profile-consent-list-item-icon.profile-consent-list-item-icon--checked svg {
  fill: #2771D5;
}
.profile-consent-list-item-icon.profile-consent-list-item-icon--unchecked svg {
  fill: #A0A0A0;
}

.profile-consent-list-item-label {
  font-size: 16px;
  margin-bottom: 0.25rem;
}

.profile-consent-list-item-value {
  font-size: 14px;
  font-style: italic;
}
.profile-consent-list-item-value span {
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 4px;
  background-color: #E5E5E5;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
}

.waste-stats {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 1.75rem;
  height: 10rem;
}
/**
 * Target indicators
 */
.widget--targets {
  padding: 1.5rem 0 2rem 0;
}

.target-indicator-header {
  text-align: center;
  letter-spacing: 1px;
}

.target-indicator-header h2 {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.target-indicator-block {
  padding: 1.75rem;
}

.barometer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.barometer-header-title {
  font-size: 0.875rem;
}

.barometer-header-target {
  font-size: 0.875rem;
}

.barometer-body {
  position: relative;
  background-color: #CCDBFF;
  height: 4px;
  border-radius: 400px;
}

.barometer-target-indicator {
  content: "";
  position: absolute;
  display: block;
  height: 1rem;
  border-right: 1px solid gray;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.barometer-fill {
  position: relative;
  width: 10%;
  height: 100%;
  background-color: #4D80FF;
  border-radius: 400px;
  transition: all 0.5s ease;
}

.barometer-value-indicator {
  content: "";
  display: block;
  position: absolute;
  right: -7px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #fff;
  border: 4px solid #4D80FF;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.barometer-value-indicator .tag {
  position: absolute;
  top: 100%;
  left: 0;
  white-space: nowrap;
  font-size: 10px;
}

.barometer-emoji {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  width: 1rem;
  background-color: #fff;
}

.barometer--with-change-label {
  padding-bottom: 1.5rem;
}

.barometer-fill--fail {
  background-color: #DE4C4C;
}

.barometer-fill--fail .barometer-value-indicator {
  border-color: #DE4C4C;
}

.barometer-fill--close {
  background-color: #E3C33A;
}

.barometer-fill--close .barometer-value-indicator {
  border-color: #E3C33A;
}

.barometer-fill--success {
  background-color: #29DB6D;
}

.barometer-fill--success .barometer-value-indicator {
  border-color: #29DB6D;
}

.barometer-fill--regular {
  background-color: #2771D5;
}

.barometer-fill--regular .barometer-value-indicator {
  border-color: #2771D5;
}

.barometer-value-indicator-number {
  position: absolute;
  display: block;
  top: 100%;
  margin-top: 0.5rem;
  font-size: 12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
}

.barometer--reversed .barometer-fill {
  position: absolute;
  right: 0;
}

.barometer--reversed .barometer-value-indicator {
  right: auto;
  left: -7px;
}

.target-date-block {
  border-top: 1px solid #d9d9d9;
  margin-top: 1.5rem;
  padding: 0.5rem 0rem 0.5rem 0rem;
  text-align: center;
}
.target-date-block .tag {
  font-size: 10px;
}

.target-date-pretitle {
  font-size: 10px;
  margin-top: 1rem;
}

.target-date-title {
  font-size: 18px;
  margin-bottom: 0.25rem;
}

.timeline-selector {
  width: 15rem;
}
.timeline-selector:nth-of-type(1) {
  margin-right: 1rem;
}
.timeline-selector:nth-of-type(2) {
  margin-left: 1rem;
}

.responsive-heatmap-container {
  height: 25rem;
}

@media only screen and (min-width: 42rem) {
  .reviews-widget-content {
    display: flex;
  }
}

.reviews-main {
  padding: 1.75rem;
  border-bottom: 1px solid #d9d9d9;
  flex-shrink: 0;
}
@media only screen and (min-width: 42rem) {
  .reviews-main {
    border-right: 1px solid #d9d9d9;
    border-bottom: 0;
  }
}

.reviews-main-title {
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 100;
}
@media only screen and (min-width: 60rem) {
  .reviews-main-title {
    font-size: 3.75rem;
  }
}

.reviews-list {
  padding: 1.75rem;
}
@media only screen and (min-width: 90rem) {
  .reviews-list {
    flex-grow: 1;
    display: flex;
  }
}

.reviews-list-item {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 90rem) {
  .reviews-list-item {
    margin: 0 1rem;
    margin-bottom: 0;
    width: 30%;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.reviews-list-item-rating-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  white-space: nowrap;
}

.reviews-list-item-rating {
  margin-right: 0.5rem;
}
.reviews-list-item-rating svg {
  width: 1rem;
  margin-right: 1px;
}

.reviews-list-item-comment {
  max-width: 20rem;
  font-size: 14px;
}
.reviews-list-item-comment span {
  white-space: nowrap;
}

.reviews-list-item-metapill {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  background-color: #2E2E2E;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
}

.pagination {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.pagination-arrow {
  cursor: pointer;
  padding: 0.25rem;
}
.pagination-arrow svg {
  display: block;
  width: 1rem;
  height: 1rem;
  fill: #707070;
}

.pagination-pages {
  padding: 0 0.75rem;
  color: #5C5C5C;
}

.widget-event-history {
  padding: 1rem;
  color: #5C5C5C;
}

.widget-event-history-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.widget-event-history-list:before {
  content: "";
  position: absolute;
  left: 6px;
  top: 2.5rem;
  display: block;
  height: calc(100% - 3rem);
  border-left: 3px solid #B4B4B4;
}

.widget-event-history-list-item {
  margin-bottom: 0.75rem;
}
.widget-event-history-list-item:first-of-type .widget-event-history-date-header {
  margin-top: 0;
}

.widget-event-history-date-header {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-top: 1rem;
}

.widget-event-history-date-header-icon {
  width: 1rem;
  margin-right: 0.5rem;
}
.widget-event-history-date-header-icon svg {
  display: block;
}

.widget-event-history-date-header-label {
  font-size: 14px;
  font-weight: 600;
}

.widget-event-history-entry {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2rem;
}
.widget-event-history-entry:before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #fff;
  border-radius: 50%;
  border: 3px solid #B4B4B4;
}

.widget-event-history-entry-time {
  margin-right: 0.5rem;
}

.widget-event-history-entry-tag {
  background-color: #E4EEFA;
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0.5rem;
}

.widget-event-history-entry-description {
  margin-left: 0.5rem;
  font-size: 14px;
}

.filters {
  position: relative;
  margin-bottom: 2rem;
  z-index: 1000;
}

.filter-preview {
  position: relative;
  border: 1px solid #DDDDDD;
  background-color: #fff;
  padding: 1rem 1.25rem;
  border-radius: 4px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
@media only screen and (min-width: 50rem) {
  .filter-preview {
    width: 23rem;
  }
}

.filter-preview-line {
  color: #6C6C6C;
}
.filter-preview-line svg {
  fill: #6C6C6C;
  width: 1rem;
  margin-right: 0.5rem;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.filter-preview-line--compared {
  font-style: italic;
}

.filter-preview-line--departments {
  font-weight: 600;
}

.three-dots {
  float: right;
  margin-top: 0.5rem;
  display: flex;
}
.three-dots span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #717171;
  border-radius: 1rem;
  margin: 0 2px;
}

.filters-dropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  border: 1px solid #DDDDDD;
  background-color: #fff;
  border-radius: 4px;
  min-width: 18rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  display: none;
  width: 100%;
}
@media only screen and (min-width: 50rem) {
  .filters-dropdown {
    width: 30rem;
  }
}

.filter-open .filters-dropdown {
  display: block;
}

.filters-dropdown-body {
  padding: 1.5rem;
}

.filter-field {
  position: relative;
  margin-bottom: 1.5rem;
}
.filter-field .department-selector,
.filter-field .date-selector {
  z-index: 30;
}

.filter-field--date1 {
  z-index: 2;
}

.filter-field--date2 {
  z-index: 1;
}

.filter-field-label {
  display: block;
  font-weight: 600;
  color: #8D8D8D;
  margin-bottom: 0.6rem;
}

.filter-field-subtitle {
  font-style: italic;
  color: #8D8D8D;
  margin: 0.5rem 0;
}

.filters-dropdown-footer {
  border-top: 1px solid #DDDDDD;
  padding: 1rem;
  text-align: center;
}
.filters-dropdown-footer .button {
  margin: 0 0.5rem;
}

.quicklink-anchor {
  display: block;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

.quicklink-icon {
  width: 3rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.quicklink-icon svg {
  display: block;
  margin-left: -0.4rem;
}
@media only screen and (min-width: 50rem) {
  .quicklink-icon {
    width: 4rem;
  }
}

.quicklink-label {
  text-transform: uppercase;
  font-size: 1rem;
  margin-right: 1rem;
}
@media only screen and (min-width: 50rem) {
  .quicklink-label {
    font-size: 1.2rem;
  }
}

.quicklink-arrow {
  width: 1rem;
}
.quicklink-arrow svg {
  fill: #707070;
}

.simple-bars-scroll-container {
  max-height: 10rem;
  overflow: scroll;
}

.simple-bars {
  padding: 1rem;
  display: table;
  width: 100%;
}

.simple-bars-list {
  display: table-row-group;
}

.simple-bars-list-item {
  display: flex;
  align-items: center;
  line-height: 2.2;
  display: table-row;
}

.simple-bars-list-item-label {
  color: #5C5C5C;
  display: table-cell;
  padding-right: 1rem;
  white-space: nowrap;
}

.simple-bars-list-item-label-group {
  display: flex;
  align-items: center;
  line-height: 1;
  max-width: 15rem;
}

.simple-bars-list-item-label-tag {
  background-color: #EDEDED;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 0.5rem;
  font-weight: 600;
}

.simple-bars-list-item-label-title {
  line-height: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.simple-bars-list-item-bar {
  flex-grow: 1;
  display: table-cell;
  width: 100%;
}

.simple-bars-list-item-bar-track {
  position: relative;
  background-color: #C9C9C9;
  height: 12px;
  border-radius: 12px;
  overflow: hidden;
}

.simple-bars-list-item-bar-fill {
  position: absolute;
  height: 100%;
  border-radius: 12px;
  transition: width 0.4s ease;
}

.simple-bars-list-item-value {
  margin-left: 1rem;
  width: 2rem;
  white-space: nowrap;
  font-size: 14px;
}

.simple-bars-footer {
  padding: 1rem;
}

.simple-bars-legends {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.simple-bars-legend {
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem;
}

.simple-bars-legend-dot {
  width: 6px;
  height: 6px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.simple-bars-legend-label {
  font-size: 14px;
}

.thick-bar {
  padding: 1rem;
}

.thick-bar-track {
  background-color: #C9C9C9;
  height: 2rem;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
}

.thick-bar-entry {
  background-color: #2771D5;
  flex-grow: 0;
  flex-shrink: 0;
  transition: width 0.4s ease;
}

.drawer-wrapper {
  overflow: hidden;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;
}

.drawer--open {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
}
.drawer--open .drawer {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.drawer--closing {
  background-color: rgba(0, 0, 0, 0);
}
.drawer--closing .drawer {
  -webkit-transform: translateX(20rem);
          transform: translateX(20rem);
}

.drawer--closed {
  display: none;
}

.drawer--opening {
  display: block;
  background-color: rgba(0, 0, 0, 0);
}
.drawer--opening .drawer {
  -webkit-transform: translateX(20rem);
          transform: translateX(20rem);
}

.drawer {
  position: absolute;
  z-index: 100;
  right: 1rem;
  border-radius: 8px;
  top: 1rem;
  width: 20rem;
  height: calc(100% - 2rem);
  background-color: #fff;
  padding: 1.5rem;
  overflow: scroll;
  -webkit-transform: translateX(20rem);
          transform: translateX(20rem);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.order-details-field {
  margin-bottom: 1rem;
}

.order-details-field-label {
  color: #5C5C5C;
  font-weight: 600;
  font-size: 14px;
}

.order-details-field-value {
  color: #5C5C5C;
  font-weight: 400;
  font-size: 14px;
}

.order-details-table {
  margin-bottom: 1rem;
}

.order-details-table-no-lines {
  color: #848484;
  font-size: 14px;
  font-style: italic;
}

.order-details-table-line-item {
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  line-height: 2.5;
  color: #272727;
  font-size: 14px;
}

.order-details-table-line-item-quanity {
  width: 1.75rem;
  font-weight: 600;
}

.order-details-line-x {
  padding: 0 0.1rem;
}

.order-details-table-line-item-name {
  flex-grow: 1;
}

.order-details-table-option {
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  line-height: 2.5;
  color: #848484;
  font-size: 14px;
  padding-left: 1.75rem;
}

.order-details-table-option-quanity {
  width: 1.75rem;
}

.order-details-table-option-name {
  flex-grow: 1;
}

.order-details-table-subtotal-row {
  display: flex;
  line-height: 2.5;
  font-size: 14px;
  border-bottom: 1px solid #D9D9D9;
  margin-top: 2px;
  border-top: 1px solid #D9D9D9;
  color: #848484;
}

.order-details-table-subtotal-row-label {
  font-size: 14px;
  flex-grow: 1;
  font-weight: 400;
}

.order-details-table-discount-row {
  display: flex;
  line-height: 2.5;
  font-size: 14px;
  color: #848484;
  border-bottom: 1px solid #D9D9D9;
}

.order-details-table-discount-row-label {
  font-size: 14px;
  flex-grow: 1;
  font-weight: 400;
}

.order-details-table-total-row {
  display: flex;
  margin-top: 1rem;
}

.order-details-table-total-row-label {
  color: #272727;
  font-size: 14px;
  flex-grow: 1;
  font-weight: 600;
}

.widget-social-media-stats {
  flex-grow: 0 !important;
}

.social-media-stats {
  background-color: #AA1671;
  color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 1.75rem;
}
@media only screen and (min-width: 90rem) {
  .social-media-stats {
    height: 100%;
    margin-bottom: 0rem;
  }
}

.social-media-primary-stats {
  padding-bottom: 0;
}

.social-media-stat-line-primary {
  margin-bottom: 1rem;
}
.social-media-stat-line-primary:last-of-type {
  margin-bottom: 1.5rem;
}

.social-media-stat-line-primary .social-media-stat-line-label {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.social-media-stat-line-primary .social-media-stat-line-value {
  font-size: 2.75rem;
  line-height: 1.1;
  font-weight: 100;
}
@media only screen and (min-width: 60rem) {
  .social-media-stat-line-primary .social-media-stat-line-value {
    font-size: 3.75rem;
  }
}

@media only screen and (min-width: 45rem) {
  .social-media-secondary-stats {
    -webkit-columns: 2;
            columns: 2;
  }
}
@media only screen and (min-width: 90rem) {
  .social-media-secondary-stats {
    -webkit-columns: 1;
            columns: 1;
  }
}

.social-media-secondary-stats-heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.social-media-stat-line-secondary {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  max-width: 20rem;
}
@media only screen and (min-width: 95rem) {
  .social-media-stat-line-secondary {
    display: flex;
    margin-bottom: 0.5rem;
  }
  .social-media-stat-line-secondary:last-of-type {
    margin-bottom: 0;
  }
}

.social-media-stat-line-secondary .stat-line-label {
  font-weight: 600;
  text-transform: uppercase;
  width: 60%;
}
@media only screen and (min-width: 95rem) {
  .social-media-stat-line-secondary .stat-line-label {
    width: 60%;
  }
}

.social-media-stat-line-secondary .stat-line-value {
  width: 40%;
}
@media only screen and (min-width: 95rem) {
  .social-media-stat-line-secondary .stat-line-value {
    width: 40%;
  }
}

.social-media-channels {
  padding: 1.5rem;
  overflow: scroll;
  width: 100%;
}

.social-media-channels-table {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.social-media-channels-table th {
  text-align: center;
  font-weight: 100;
  font-size: 12px;
}

.social-media-channels-table-row {
  height: 4rem;
}

.social-media-channels-table-field {
  position: relative;
}
.social-media-channels-table-field:nth-of-type(1) {
  width: 3rem;
}
.social-media-channels-table-field:nth-of-type(2) {
  width: 8rem;
}
.social-media-channels-table-field:nth-last-of-type(1) {
  width: 10rem;
  text-align: right;
  padding: 0 1rem;
}
.social-media-channels-table-field.disabled a, .social-media-channels-table-field.disabled button.menu-item-anchor {
  opacity: 0.4;
  cursor: default;
}

.social-media-channels-table-field-content {
  display: flex;
  align-items: flex-start;
  padding: 0 0.5rem;
  justify-content: flex-start;
}

.social-media-channels-channel-icon {
  background-color: #EBEBEB;
  width: 2.625rem;
  height: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.social-media-channels-channel-icon svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #454545;
}

.social-media-channels-table-field-button {
  background-color: #EBEBEB;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  white-space: nowrap;
}

.social-media-channels-channel-icon--instagram,
.social-media-channels-table-field-button--instagram {
  background-color: #F4E7F4;
  color: #D122D1;
  text-decoration: none;
}
.social-media-channels-channel-icon--instagram svg,
.social-media-channels-table-field-button--instagram svg {
  fill: #D122D1;
}

.social-media-channels-channel-icon--facebook,
.social-media-channels-table-field-button--facebook {
  background-color: #D9E1F2;
  color: #3B5998;
  text-decoration: none;
}
.social-media-channels-channel-icon--facebook svg,
.social-media-channels-table-field-button--facebook svg {
  fill: #3B5998;
}

.social-media-channels-channel-icon--tiktok,
.social-media-channels-table-field-button--tiktok {
  background-color: #EBEBEB;
  color: #454545;
  text-decoration: none;
}
.social-media-channels-channel-icon--tiktok svg,
.social-media-channels-table-field-button--tiktok svg {
  fill: #454545;
}

.social-media-channels-channel-icon--linkedin,
.social-media-channels-table-field-button--linkedin {
  background-color: #E1EDF8;
  color: #0A67C2;
  text-decoration: none;
}
.social-media-channels-channel-icon--linkedin svg,
.social-media-channels-table-field-button--linkedin svg {
  fill: #0A67C2;
}

.social-media-channels-channel-icon--youtube,
.social-media-channels-table-field-button--youtube {
  background-color: #F5DCDC;
  color: #FF0000;
  text-decoration: none;
}
.social-media-channels-channel-icon--youtube svg,
.social-media-channels-table-field-button--youtube svg {
  fill: #FF0000;
}

.social-media-channels-channel-icon--pinterest,
.social-media-channels-table-field-button--pinterest {
  color: #454545;
  text-decoration: none;
}

.social-media-channels-table-row--Pinterest {
  opacity: 0.4;
}

.social-media-channels-table-field-title-block {
  padding: 0 1rem;
  white-space: nowrap;
}
.social-media-channels-table-field-title-block.social-media-channels-table-field-title-block--center {
  text-align: center;
  min-width: 8rem;
}

.social-media-channels-table-field-title-block-main {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
}

.social-media-channels-table-field-title-block-secondary {
  font-size: 12px;
  color: #8E8F91;
}
.social-media-channels-table-field-title-block-secondary a, .social-media-channels-table-field-title-block-secondary button.menu-item-anchor {
  color: inherit;
  text-decoration: none;
}
.social-media-channels-table-field-title-block-secondary a:hover, .social-media-channels-table-field-title-block-secondary button.menu-item-anchor:hover {
  text-decoration: underline;
}

.social-media-channels-table-field-progress {
  background-color: #EBEBEB;
  margin-left: -0.75rem;
  font-weight: 600;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}
.social-media-channels-table-field-progress.social-media-channels-table-field-progress--positive {
  color: #00BA07;
}
.social-media-channels-table-field-progress.social-media-channels-table-field-progress--warning {
  color: #E3C33A;
}
.social-media-channels-table-field-progress.social-media-channels-table-field-progress--negative {
  color: #B51818;
}

.top-posts {
  display: flex;
  overflow: scroll;
  padding: 0.75rem;
}

.top-posts-item {
  padding: 0.75rem;
}
.top-posts-item:last-child {
  padding-right: 1.5rem;
}

.top-posts-item-image {
  display: block;
  position: relative;
  background-color: gray;
  width: 7rem;
  height: 7rem;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  color: inherit;
}

.top-posts-item-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(219deg, rgba(2, 0, 36, 0.7) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
}

.top-posts-item-image-type {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.top-posts-item-image-type svg {
  fill: #fff;
}

.top-posts-item-image-stat {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 100%;
  border-radius: 1rem;
  padding: 0.125rem 0.75rem;
  background-color: #fff;
  font-size: 14px;
}

.top-posts-item-date {
  text-align: center;
  font-size: 12px;
  margin-top: 0.4rem;
  color: #8E8F91;
} /**
 * Partials
 */
@media only screen and (min-width: 60rem) {
  .app-sidebar {
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 19rem;
    display: flex;
    border-right: 1px solid #D5D5D5;
  }
}

@media only screen and (min-width: 60rem) {
  .app-content {
    padding-left: 19rem;
  }
}

.app-panel {
  display: none;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  height: calc(100vh - 6.5rem);
}
@media only screen and (min-width: 60rem) {
  .app-panel {
    display: flex;
    position: fixed;
    height: 100%;
    width: 18rem;
    border-right: 1px solid #D5D5D5;
  }
}

.navigation-open .page-content {
  display: none;
}
@media only screen and (min-width: 60rem) {
  .navigation-open .page-content {
    display: block;
  }
}

@media only screen and (min-width: 60rem) {
  .app-panel ~ .page-content {
    padding-left: 18rem;
  }
}

.page-content-inner {
  position: relative;
  padding: 2.25rem 1rem;
}
@media only screen and (min-width: 60rem) {
  .page-content-inner {
    padding: 2.25rem 2rem;
  }
}

.page-content-inner-centering {
  max-width: 45rem;
  margin: 0 auto;
}

.page-content-inner-centering--wide {
  max-width: 90rem;
  margin: 0 auto;
}

@media only screen and (min-width: 50rem) {
  .page-content-header {
    display: flex;
    justify-content: space-between;
  }
}

.page-title {
  margin: 0;
  margin-right: 1rem;
}

.page-subtitle {
  color: #A0A0A0;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
  margin-top: -0.25rem;
  margin-bottom: 1rem;
}

.page-description {
  color: #606060;
}

.page-chart-with-sidebar-element {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 90rem) {
  .page-chart-with-sidebar-element {
    display: flex;
    flex-wrap: nowrap;
  }
}

.page-chart-with-sidebar-element-sidebar {
  width: 100%;
}
@media only screen and (min-width: 90rem) {
  .page-chart-with-sidebar-element-sidebar {
    width: 23rem;
    flex-shrink: 0;
    flex-grow: 1;
    order: 2;
    display: flex;
    flex-direction: column;
  }
  .page-chart-with-sidebar-element-sidebar > * {
    flex-shrink: 1;
    flex-grow: 1;
  }
}
@media only screen and (min-width: 90rem) and (min-width: 90rem) {
  .page-chart-with-sidebar-element-sidebar > *:nth-of-type(1) {
    margin-bottom: 1rem;
  }
}

.page-chart-with-sidebar-element-main {
  width: 100%;
}
@media only screen and (min-width: 90rem) {
  .page-chart-with-sidebar-element-main {
    order: 1;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 90rem) {
  .page-with-sidebar {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 90rem) {
  .page-with-sidebar-sidebar {
    width: 25rem;
  }
}

.page-with-sidebar-main {
  width: 100%;
}
@media only screen and (min-width: 90rem) {
  .page-with-sidebar-main {
    margin-left: 1rem;
  }
}

.split-chart-container-1-3 {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 75rem) {
  .split-chart-container-1-3 {
    display: flex;
    align-items: stretch;
  }
}
.split-chart-container-1-3 .widget-v3-container,
.split-chart-container-1-3 .widget-v3 {
  height: 100%;
  margin-bottom: 0;
}

.split-chart-container-widget:nth-of-type(1) {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 75rem) {
  .split-chart-container-widget:nth-of-type(1) {
    width: 19rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.split-chart-container-widget:nth-of-type(2) {
  flex-grow: 1;
}
.split-chart-container-widget:nth-of-type(2) .widget-v3 {
  display: flex;
  flex-direction: column;
}
.split-chart-container-widget:nth-of-type(2) .widget-v3-body {
  flex-grow: 1;
}

.split-chart-container-5-boxes {
  display: grid;
  grid-template-columns: 1fr 1rem 1fr 1rem 1fr 1rem 1fr;
  grid-template-rows: 1fr 1rem 1fr;
  margin-bottom: 1rem;
}

.split-chart-container-5-boxes-widget {
  background-color: seagreen;
}
.split-chart-container-5-boxes-widget:nth-of-type(1) {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  height: 8.25rem;
}
.split-chart-container-5-boxes-widget:nth-of-type(2) {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: span 1;
}
.split-chart-container-5-boxes-widget:nth-of-type(3) {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}
.split-chart-container-5-boxes-widget:nth-of-type(4) {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
}
.split-chart-container-5-boxes-widget:nth-of-type(5) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 4;
}

.redacted {
  color: transparent;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
}

h1 .redacted {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.brand-bar {
  background-color: #2771D5;
  height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 60rem) {
  .brand-bar {
    height: 100%;
    width: 4rem;
    flex-direction: column;
    justify-content: start;
    padding-top: 1.25rem;
  }
}

.brand-bar-logo {
  width: 2.5rem;
}
.brand-bar-logo path {
  fill: #ffffff;
}

.brand-bar-title {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: rotate(90deg) translateX(1.5rem);
          transform: rotate(90deg) translateX(1.5rem);
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}

.navigation-bar-mobile {
  border-bottom: 1px solid #D6D6D6;
  background-color: #fff;
  height: 3.25rem;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 60rem) {
  .navigation-bar-mobile {
    display: none;
  }
}

.navigation-bar-mobile-trigger-container {
  height: 100%;
  width: 3.25rem;
  margin-right: 0.5rem;
}

.navigation-bar-mobile-trigger {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation-bar-mobile-trigger-line-wrapper {
  width: 1.875rem;
}

.navigation-bar-mobile-trigger-line {
  position: relative;
  background-color: #101010;
  height: 4px;
  margin-bottom: 4px;
  transition: all 0.2s ease;
}
.navigation-bar-mobile-trigger-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #101010;
  transition: all 0.1s ease;
}

.navigation-open .navigation-bar-mobile-trigger-line:nth-of-type(1) {
  display: none;
}
.navigation-open .navigation-bar-mobile-trigger-line:nth-of-type(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.navigation-open .navigation-bar-mobile-trigger-line:nth-of-type(2):before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.navigation-open .navigation-bar-mobile-trigger-line:nth-of-type(3) {
  display: none;
}

.navigation-bar-mobile-heading {
  margin-top: -4px;
  font-weight: 600;
  font-size: 1.1rem;
}

.navigation {
  position: absolute;
  z-index: 2;
  height: calc(100vh - 6.5rem);
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  display: none;
}
@media only screen and (min-width: 60rem) {
  .navigation {
    position: relative;
    width: 15rem;
    height: 100%;
    display: block;
    padding: 2rem 1.875rem;
  }
}

.navigation-open .navigation {
  display: block;
}

.menu-items-container {
  color: #8B8B8B;
  font-size: 14px;
}

.menu-items-container > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-items-container > ul > li {
  margin-bottom: 1.5rem;
}
.menu-items-container > ul > li > a, .menu-items-container > ul > li > button.menu-item-anchor {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.menu-item-icon {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.625rem;
}
.menu-item-icon path {
  fill: #8B8B8B;
}

.menu-items-container > ul > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 2rem;
}

.menu-items-container > ul > li ul > li {
  margin-top: 0.75rem;
}
.menu-items-container > ul > li ul > li > a, .menu-items-container > ul > li ul > li > button.menu-item-anchor {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #8B8B8B;
  outline: none;
}

.current-menu-item {
  color: #2771D5 !important;
}
.current-menu-item > .menu-item-icon path {
  fill: #2771D5;
}
.current-menu-item > .menu-item-label {
  color: #2771D5;
}

button.menu-item-anchor {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
}

.submenu-container--hidden {
  display: none;
}

.menu-item-anchor--disabled {
  opacity: 0.4;
  pointer-events: none;
}

body.page-template-login-page {
  background-color: #2771D5;
}

@media only screen and (min-width: 60rem) {
  .login-split-view {
    display: flex;
  }
}

@media only screen and (min-width: 60rem) {
  .login-split-view-main {
    flex-grow: 1;
    height: 100vh;
  }
}

.login-split-view-main-content {
  color: #fff;
  padding: 1.75rem;
}
@media only screen and (min-width: 60rem) {
  .login-split-view-main-content {
    margin-left: 6rem;
    margin-top: 3.75rem;
  }
}

.login-logo {
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 5rem;
}
.login-logo svg path {
  fill: #fff;
}

.login-title {
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  max-width: 25rem;
}
@media only screen and (min-width: 45rem) {
  .login-title {
    font-size: 4rem;
    font-weight: 600;
  }
}

.login-description {
  margin-bottom: 5rem;
  max-width: 25rem;
}
@media only screen and (min-width: 45rem) {
  .login-description {
    margin-bottom: 4rem;
    max-width: 27rem;
  }
}

.login-split-view-side {
  background-color: #fff;
  background-color: #2771D5;
}
@media only screen and (min-width: 45rem) {
  .login-split-view-side {
    width: 35rem;
  }
}

/**
 * Code below is PRE SCSS. Waiting to be refactored into seperate imports
**************************/
.MuiTableFooter-root .MuiTableCell-root {
  border-bottom: 0;
}

/**
 * Partial: Front page card
 */
.front-page-card-wrapper {
  position: relative;
  height: 100vh;
}

.front-page-card {
  position: relative;
  max-width: 50rem;
  margin: 0 auto;
  padding: 2rem;
  top: 50%;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
  text-align: center;
}
.front-page-card .apron-icon {
  width: 10rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 60rem) {
  .front-page-card .apron-icon {
    width: 13rem;
    margin-bottom: 3rem;
  }
}
.front-page-card-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2.125rem;
  font-weight: 400;
}

@media only screen and (min-width: 60rem) {
  .front-page-card-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 3.75rem;
  }
}
.front-page-card-paragraph {
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 60rem) {
  .front-page-card-paragraph {
    margin-bottom: 3rem;
  }
}
/**
 * Module: profile-card
 */
.profile-card {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.header .profile-card {
  padding-left: 2rem;
  margin-left: 0.5rem;
}

.header .profile-card:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  height: 2rem;
  background-color: lightgray;
  width: 1px;
}

.profile-card-avatar {
  background-color: lightgray;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-size: cover;
  background-position: center center;
}

.profile-card-name {
  /*text-transform: capitalize;*/
  font-size: 0.9rem;
}

.profile-card-tagline {
  display: inline-block;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

/**
 * Partial: Main Content;
 */
.main-content {
  max-width: 100rem;
  margin: 2rem auto 0 auto;
}

/**
 * Basics: Forms;
 */
/**
 * Fragment: Spinner;
 */
.spinner-height-wrapper {
  height: 15rem;
}

.spinner-page-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.spinner-centered {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #4D80FF;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

.spinner--white .spinner {
  background-color: #FFFFFF;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/**
 * Performance overview
 **/
.performance-header {
  margin-bottom: 2rem;
}

.performance-header h1 {
  margin-top: 0;
}

.spinner-performance-header-container {
  position: relative;
  top: 0.5rem;
  display: inline-block;
  width: 4rem;
  height: 2rem;
}

.filter-button-container {
  margin-top: 0.5rem;
}

.filter-button {
  background-color: #4D80FF;
  border: none;
  color: #fff;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  width: 100%;
}

.filter-block-department select {
  width: 100%;
}

.start-date-container {
  margin-top: 0.5rem;
}

.start-date-container input {
  width: 49%;
  padding: 0.5rem 0.5rem;
  margin-right: 2%;
}

.start-date-container input:nth-of-type(2) {
  margin-right: 0;
}

@media only screen and (min-width: 80rem) {
  .performance-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .performance-header-filter {
    width: 30%;
  }
}
/**
 * Widgets
 */
.widget {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
}

.widget-header {
  padding: 2rem 2rem 0 2rem;
}

.widget-title {
  margin: 0;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #A2A2A2;
}

.responsive-line-container {
  height: 23rem;
}

.widget-v2-container--timeline-main .responsive-line-container {
  height: 28rem;
}

.responsive-line-error {
  background-color: #DB7272;
  text-align: center;
  color: #fff;
  padding: 1rem;
}

.widget-container--2-1-split {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.widget-container--2-1-split .widget-subcontainer:nth-of-type(1) {
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 2;
}

.x-widget-container--2-1-split .widget-subcontainer:nth-of-type(1) .widget {
  height: calc(50% - 1rem);
}

.widget-container--2-1-split .widget-subcontainer:nth-of-type(2) {
  width: 100%;
  order: 1;
}

.x-widget-container--2-1-split .widget-subcontainer:nth-of-type(2) .widget {
  min-height: 100%;
}

@media only screen and (min-width: 80rem) {
  .widget-container--2-1-split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50rem;
    margin-bottom: 2rem;
  }
  .widget-container--2-1-split .widget-subcontainer:nth-of-type(1) {
    width: calc(70% - 2rem);
    display: flex;
    flex-direction: column;
    order: 1;
  }
  .widget-container--2-1-split .widget-subcontainer:nth-of-type(2) {
    width: 30%;
    order: 2;
  }
  /*.widget:last-of-type {
  	margin-bottom: 0;
  	border-bottom: 0;	
  }*/
}
.widget-block {
  padding: 2.5rem;
  border-bottom: 1px solid #d9d9d9;
}

.x-widget-block:last-of-type {
  border-bottom: none;
}

.widget-block-title {
  color: #A2A2A2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.widget-block-metric {
  position: relative;
  font-size: 3.125rem;
  font-weight: 100;
  margin-bottom: 0.5rem;
}

.widget-block-metric--with-pre {
  padding-left: 2.5rem;
}

.widget-block-metric-pre {
  position: absolute;
  top: 0.3rem;
  left: 0;
  font-size: 1rem;
  font-weight: bold;
}

.widget-block-subtitle {
  font-size: 0.875rem;
  color: #A2A2A2;
  font-style: italic;
  line-height: 1.3;
}

/**
 * Widget-v2 Products over timer
 */
.widget-timeline-split-selector {
  display: flex;
  flex-direction: column;
  width: 100%;
  order: 2;
  border-bottom: 1px solid #F4F4F4;
}

.widget-timeline-split-timeline {
  width: 100%;
}

@media only screen and (min-width: 70rem) {
  .widget-timeline-split-container {
    display: flex;
  }
  .widget-timeline-split-selector {
    width: 30%;
    border-left: 1px solid #F4F4F4;
  }
  .widget-timeline-split-timeline {
    width: 70%;
  }
}
.widget-timeline-input-container {
  border-bottom: 1px solid #F4F4F4;
}

.widget-timeline-input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: none;
  outline: none;
  font-size: 0.9rem;
}

.widget-timeline-product-list-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: 10rem; /* Only to let flex-grow do it's thing*/
  flex-grow: 1;
}

.widget-timeline-product-list {
  padding: 0.75rem;
  margin: 0;
  list-style: none;
  font-size: 0.85rem;
}

.widget-timeline-product-list-item {
  background-color: #F0F0F0;
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: background-color 0.2s ease;
}

.widget-timeline-product-list-item:hover {
  background-color: #e3e3e3;
}

.widget-timeline-selected-list-container {
  display: flex;
  justify-content: space-between;
}

.widget-timeline-selected-list {
  padding: 0.75rem;
  margin: 0;
  list-style: none;
  font-size: 0.85rem;
}

.widget-timeline-selected-list-item {
  display: inline-block;
  position: relative;
  background-color: #2771D5;
  transition: all 0.2s ease;
  color: #fff;
  border-radius: 3px;
  padding: 0.3rem 0.6rem;
  padding-right: 4rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.widget-timeline-selected-list-item-close {
  display: block;
  position: absolute;
  top: 50%;
  right: 0.6rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  color: #000;
  text-align: center;
  font-size: 0.6rem;
  line-height: 1.6;
  cursor: pointer;
}
.widget-timeline-selected-list-item-close svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
}

.no-options {
  padding-left: 0.75rem;
  margin: 0;
  list-style: none;
  font-size: 0.85rem;
  font-style: italic;
}

.product-timeline-clear {
  display: inline-block;
  cursor: pointer;
  padding: 1rem;
  margin: 0;
  list-style: none;
  font-size: 0.85rem;
  color: #2771D5;
  white-space: nowrap;
}

.product-timeline-clear--disabled {
  cursor: default;
  color: gray;
}

/**
 * Table
 */
.test-table-container {
  padding: 2rem;
}

.test-table {
  width: 100%;
  border-collapse: collapse;
}

.test-table th,
.test-table td {
  border: 1px solid #A2A2A2;
  text-align: left;
  padding: 0.5rem;
  font-size: 0.825rem;
}

/**
 * Datepicker
 */
.date-picker-container {
  margin-top: 0.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 20;
}

.date-picker-container-arrow {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 2.5rem;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker-select {
  position: relative;
}

.date-picker-container .datepicker-select {
  width: 49%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.datepicker-select-input {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: hsl(0, 0%, 20%);
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker-select-body {
  flex-grow: 1;
}

.datepicker-select-toggler {
  padding: 0 0.2rem 0 0.75rem;
  /*border-left: 1px solid rgba(0,0,0,.3);*/
}

.arrow {
  border: solid rgba(0, 0, 0, 0.2);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.up {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  position: relative;
  top: 2px;
}

.down {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: relative;
  top: -2px;
}

.left {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  position: relative;
  top: 0px;
  right: -2px;
}

.right {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: relative;
  top: 0px;
  right: 2px;
}

.datepicker-select-container {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: calc(100% + 0.25rem);
  left: 0;
  border: 1px solid #d9d9d9;
}

.datepicker-select:nth-of-type(3) .datepicker-select-container {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  top: calc(100% + 0.25rem);
  right: 0;
  left: auto;
  border: 1px solid #d9d9d9;
}

/**
 * Departments container
 */
.select-department-container {
  position: relative;
  z-index: 22;
  display: flex;
}

.department-selector {
  flex-grow: 1;
  margin-right: 0.25rem;
}

.select-department-switcher {
  width: 2.5rem;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.select-department-switcher svg {
  display: block;
  width: 1.5rem;
  fill: #6E6E6E;
}

.select-department-switcher-active {
  border: none;
  background-color: #4D80FF;
}

.select-department-switcher-active svg {
  fill: #fff;
}

/**
 * Pie
 */
.pie-container {
  height: 32rem;
  max-width: 90%;
  margin-top: 2rem;
}

/**
 * Shifts
 */
.widget-content--shift-list {
  min-height: 5rem;
}

.shift-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shift-list-item {
  background-color: #EDEDED;
  margin-bottom: 0.25rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.shift-is-approved {
  background-color: rgb(41, 208, 169);
  color: #fff;
}

.shift-is-sick {
  background-color: rgb(227, 63, 63) !important;
  color: #fff;
}

.shift-legends {
  text-align: center;
}

.shift-legend {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin: 1rem;
}

.shift-legend-approved:before {
  content: "";
  position: relative;
  top: 0.125rem;
  display: inline-block;
  background-color: rgb(41, 208, 169);
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.shift-legend-not-approved:before {
  content: "";
  position: relative;
  top: 0.15rem;
  display: inline-block;
  background-color: #EDEDED;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.shift-legend-sick:before {
  content: "";
  position: relative;
  top: 0.15rem;
  display: inline-block;
  background-color: rgb(227, 63, 63);
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

/**
 * Production table (sits on top of the defualt react-table styling)
 * production-table is our specific table wrapper class
 */
.rene-react-table-container {
  padding: 2rem;
}

.rene-react-table {
  border: 1px solid #d5d5d5 !important;
  border-bottom: none !important;
}

.rene-react-table-title {
  margin-top: 0;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.rene-react-table .rt-thead {
  box-shadow: none !important;
  color: #fff;
}

.rene-react-table .rt-thead .rt-th {
  background-color: #4D80FF !important;
  text-align: left;
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
  padding: 1rem !important;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.rene-react-table * {
  outline: none !important;
}

.XXXX .rene-react-table .rt-thead .rt-th:first-of-type {
  background-color: #285ee2 !important; /* Darker blue */
}

.rene-react-table .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #d5d5d5 !important;
}

.rene-react-table .rt-tbody .rt-td {
  border-right: none !important;
  border-left: none !important;
  padding: 1rem !important;
}

.production-table .rt-tbody .rt-td:first-of-type,
.production-table .rt-tbody .rt-td:last-of-type {
  background-color: #efefef !important; /* gray */
}

/**
 * bar chart
 */
.widget-content--bar {
  height: 40rem;
  padding: 2rem;
}

.countdown {
  font-weight: bold;
  font-size: 3rem;
  margin: 0;
}

.timeline-filters {
  float: left;
  list-style: none;
  margin: 0;
  padding: 1rem 2rem 2rem 2rem;
  text-align: center;
}

.timeline-filter {
  font-family: verdana;
  font-size: 14px;
  margin: 0 0.75rem;
  display: inline-block;
  color: #6E6E6E;
  cursor: pointer;
}

.timeline-filter--active {
  color: #4D80FF;
}

.timeline-settings-wrapper {
  float: right;
  padding: 1rem 2rem 2rem 2rem;
}

.timeline-settings-wrapper .timeline-settings-button {
  border: 1px solid gray;
  background-color: transparent;
  font-family: "brown";
  padding: 0.4rem 0.75rem;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  margin-left: 0.5rem;
}

.timeline-settings-wrapper .timeline-settings-button--active {
  background-color: #4D80FF;
  border: 1px solid #4D80FF;
  color: #fff;
}

.timeline-settings-button-green--active {
  background-color: #29D0A9 !important;
  border: 1px solid #29D0A9 !important;
  color: #fff;
}

/**
 * ROLF / Audits
 **/
.widget--audits {
  padding: 1.5rem 0 0 0;
}

.audits-header {
  text-align: center;
  letter-spacing: 1px;
}

.audits-header h2 {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.audits-score {
  padding: 0.5rem 0 1rem 0;
  font-size: 4rem;
  font-weight: 200;
  text-align: center;
}

.audits-footer {
  text-align: center;
  font-style: italic;
  color: #A2A2A2;
  font-size: 14px;
  margin-bottom: 1rem;
}

.audits-footer span {
  font-weight: bold;
}

.audits-list {
  padding: 1rem;
}

.audit-item {
  display: flex;
  justify-content: space-between;
  background-color: #F2F2F2;
  padding: 0.5rem;
  margin-bottom: 2px;
}

.audit-item svg {
  width: 1rem;
  height: 1rem;
}

/**
 * Take Away barlines
 */
.barlines-container {
  padding: 2rem;
}

.barlines-header {
  display: none;
}

@media only screen and (min-width: 60rem) {
  .barlines-header {
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: #A2A2A2;
    text-transform: uppercase;
  }
}
.barlines-header-line:nth-of-type(1) {
  width: 11.5rem;
  margin-right: 1rem;
}

.barlines-header-line:nth-of-type(2) {
  flex-grow: 1;
}

.barlines-header-line:nth-of-type(3) {
  width: 12rem;
  margin-left: 1rem;
}

.barline {
  height: 2.5rem;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 60rem) {
  .barline {
    display: flex;
    align-items: center;
    height: 2.5rem;
    margin-bottom: 1rem;
  }
}
.barline-header {
  width: 10rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 60rem) {
  .barline-header {
    margin-bottom: 0;
  }
}
.barline-header-dot {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  float: left;
}

@media only screen and (min-width: 60rem) {
  .barline-header-dot {
    float: none;
  }
}
.barline-bar-container {
  flex-grow: 1;
}

.barline-bar {
  position: relative;
  background-color: #DDDDDD;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 60rem) {
  .barline-bar {
    position: relative;
    background-color: #DDDDDD;
    padding: 0.5rem;
  }
}
.barline-bar-percentage {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #4D80FF;
  transition: width 0.4s ease;
}

.barline-bar-number {
  position: relative;
  color: #fff;
}

@media only screen and (min-width: 60rem) {
  .barline-footer {
    width: 12rem;
    margin-left: 1rem;
  }
}
/**
 * Scatterplot scatter chart
 */
.scatter-plot-container {
  height: 25rem;
}

.widget-container--scatter {
  margin-bottom: 1rem;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #D0D0D0;
}

.scatterchart-category-select-container {
  padding: 1rem 2rem 0 2rem;
  display: flex;
  justify-content: flex-end;
}

.scatterchart-category-select {
  width: 15rem;
}

/**
 * action-card
 */
.action-card {
  background-color: #fff;
  margin-bottom: 2rem;
}

.action-card-header {
  padding: 1rem;
  border-bottom: 1px solid #BFBFBF;
}

.action-card-header h2 {
  margin: 0;
  font-size: 1.1rem;
  color: #5C5C5C;
}

.action-card-body {
  padding: 1rem;
  color: #4E4E4E;
}
