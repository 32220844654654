.reviews-widget-content {
	@media only screen and (min-width: 42rem) {
		display: flex;
	}
}

	
	.reviews-main {
		padding: 1.75rem;
		border-bottom: 1px solid #d9d9d9;
		flex-shrink: 0;
		@media only screen and (min-width: 42rem) {
			border-right: 1px solid #d9d9d9;
			border-bottom: 0;
		}
	}

		.reviews-main-title {
			font-size: 2.75rem;	
			line-height: 1.1;
			font-weight: 100;
			@media only screen and (min-width: 60rem) {
				font-size: 3.75rem;	
			}
		}

	.reviews-list {
		padding: 1.75rem;
		@media only screen and (min-width: 90rem) {
			flex-grow: 1;
			display: flex;
		}
	}

		.reviews-list-item {
			margin-bottom: 2rem;
			@media only screen and (min-width: 90rem) {
				margin: 0 1rem;
				margin-bottom: 0;
				width: 30%;
				flex-grow: 0;
				flex-shrink: 0;
			}
		}


			.reviews-list-item-rating-line {
				display: flex;
				align-items: center;
				margin-bottom: .25rem;
				white-space: nowrap;
			}

				.reviews-list-item-rating {
					margin-right: .5rem;
					svg {
						width: 1rem;
						margin-right: 1px;
					}
				}

				.reviews-list-item-date {
				}

			.reviews-list-item-comment {
				max-width: 20rem;
				font-size: 14px;
				span {
					white-space: nowrap;
				}
			}

			.reviews-list-item-metapill {
				display: inline-block;
				margin-top: .5rem;
				padding: .25rem 1rem;
				border-radius: 1rem;
				background-color: #2E2E2E;
				color: #fff;
				font-size: 12px;
				white-space: nowrap;
			}


.pagination {
	display: flex;
	align-items: center;
	user-select: none;
}
	.pagination-arrow {
		cursor: pointer;
		padding: .25rem;
		svg {
			display: block;
			width: 1rem;
			height: 1rem;
			fill: #707070;
		}
	}

	.pagination-pages {
		padding: 0 .75rem;
		color: #5C5C5C;
	}