.featured-stats {
	display: flex;
	margin: 0 auto;
	align-items: center;
}

	.featured-stat-block {
		position: relative;
		margin-right: 0rem;
		flex-shrink: 0;
		// &:nth-of-type(1) {
		// 	width: 3rem;
		// }

		// &:nth-of-type(2) {
		// 	width: 8rem;
		// }

		// &:nth-last-of-type(1) {
		// 	width: 10rem;
		// 	text-align: right;
		// 	padding: 0 1rem;
		// }

		&.disabled a {
			opacity: .4;
			cursor: default
		}

	}

		.featured-stat-block-content {
			display: flex;
			align-items: flex-start;
			padding: 0 .5rem;
			justify-content: flex-start;
		}


		.featured-stat-block-title-block {
			padding: 0 1rem;
			white-space: nowrap;

			&.featured-stat-block-title-block--center {
				text-align: center;
				min-width: 8rem;
			}
		}

			.featured-stat-block-title-block-main {
				font-weight: bold;
				font-size: 18px;
				line-height: 1.2;
			}

			.featured-stat-block-title-block-secondary {
				font-size: 12px;
				color: #8E8F91;

				a {
					color: inherit;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}

		.featured-stat-block-progress {
			background-color: #EBEBEB;
			margin-left: -.75rem;
			font-weight: 600;
			font-size: 12px;
			padding: .25rem .5rem;
			border-radius: 5px;
			&.social-media-channels-table-field-progress--positive { color: #00BA07; }
			&.social-media-channels-table-field-progress--warning { color: #E3C33A; }
			&.social-media-channels-table-field-progress--negative { color: #B51818; }

		}